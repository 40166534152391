import { useCallback, useEffect, useRef, useState } from 'react';

import { getIso2 } from '@rbilabs/intl';
import { GraphQLError } from 'graphql';
import { useIntl } from 'react-intl';

import { encryptOrbitalCard } from 'components/add-payment-method-modal/orbital-encrypt-card';
import { FirstDataAcceptedCardsToCardType } from 'components/credit-card-form-inputs/first-data-credit-card-form-inputs/types';
import {
  CartPaymentCardType,
  IAddAccountInput,
  IPrepaidsMergeInput,
  PaymentProcessor,
  UserAccountsDocument,
  useDeleteAccountMutation,
  usePrepaidsMergeMutation,
  useUserAccountsQuery,
} from 'generated/rbi-graphql';
import { usePrepaidsReload } from 'hooks/prepaid';
import { IPrepaidsReload } from 'hooks/prepaid/use-prepaids-reload';
import { useThreeDS } from 'hooks/use-threeDS';
import { UseThreeDS } from 'hooks/use-threeDS/types';
import { getNonce } from 'remote/api/first-data';
import { HttpErrorCodes } from 'remote/constants';
import useApplePay from 'state/apple-pay/hooks/use-apple-pay';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import useGooglePay from 'state/google-pay/hooks/use-google-pay';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import { ThreeDSChallengeError, ThreeDSMethodError } from 'state/payment/hooks/errors';
import { Context, StatusType, dataDogLogger } from 'utils/datadog';
import { storePrepaidCard } from 'utils/encryption';
import { getCustomerIdForCRMStack } from 'utils/environment';
import { sanitizeAlphanumeric, sanitizeNumber } from 'utils/form';
import { ISOs } from 'utils/form/constants';
import logger from 'utils/logger';
import {
  IAdyenPaymentState,
  IPaymentPayload,
  IPaymentState,
  hiddenFieldValueByPaymentProcessor,
  parseUkPostCode,
  splitExpiry,
} from 'utils/payment';
import { removeEmailPrefix } from 'utils/remove-email-prefix';
import { handleThreeDSPayment } from 'utils/threeDS';

import { CASH_ACCOUNT_IDENTIFIER, DEFAULT_PAYMENT_METHOD_PLACEHOLDER } from '../constants';
import { IAddPaymentMethodOptions, IPaymentMethod, IReloadPrepaidCard } from '../types';

import { getPaymentMethodsState } from './getPaymentMethodsState';
import { IInitPaymentMethods, IPayment, RedirectData, ThreeDSData, ThreeDSType } from './types';
import { userAccountsToPaymentMethods } from './utils';

const cashAccount: IPaymentMethod = {
  ...DEFAULT_PAYMENT_METHOD_PLACEHOLDER,
  cash: true,
  fdAccountId: CASH_ACCOUNT_IDENTIFIER,
  accountIdentifier: CASH_ACCOUNT_IDENTIFIER,
  onlinePayment: false,
};

const usePayment = ({
  getEncryptionDetailsMutation,
  cdp,
  openErrorDialog,
  user,
  isGuestAuthenticated,
  updateUserInfo,
  addCreditAccountMutation,
}: IPayment) => {
  const { formatMessage } = useIntl();
  const { feCountryCode } = useLocale();
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [allPaymentMethods, setAllPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [hasGetPaymentMethodsError, setHasGetPaymentMethodsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { canUseApplePay, applePayCardDetails } = useApplePay({});
  const { canUseGooglePay, googlePayCardDetails } = useGooglePay();
  const enableCashPayment = useFlag(LaunchDarklyFlag.ENABLE_CASH_PAYMENT);
  const enableFirstValidPaymentMethodIdFoundAsDefault = useFlag(
    LaunchDarklyFlag.ENABLE_FIRST_VALID_PAYMENT_METHOD_ID_FOUND_AS_DEFAULT
  );
  const enableBlik = useFlag(LaunchDarklyFlag.ENABLE_BLIK_PAYMENT);
  const enablePayPal = useFlag(LaunchDarklyFlag.ENABLE_PAYPAL_PAYMENTS);
  const enableSodexoVoucher = useFlag(LaunchDarklyFlag.ENABLE_SODEXO_VOUCHER_PAYCOMET);
  const enableChequeGourmetVoucher = useFlag(
    LaunchDarklyFlag.ENABLE_CHEQUE_GOURMET_VOUCHER_PAYCOMET
  );
  const enableTicketRestaurantVoucher = useFlag(
    LaunchDarklyFlag.ENABLE_TICKET_RESTAURANT_VOUCHER_PAYCOMET
  );
  const enablePayPalPaycomet = useFlag(LaunchDarklyFlag.ENABLE_PAYPAL_PAYCOMET);
  const enableSodexo = useFlag(LaunchDarklyFlag.ENABLE_SODEXO_CREDIT_CARD_PAYCOMET);
  const enableChequeGourmet = useFlag(LaunchDarklyFlag.ENABLE_CHEQUE_GOURMET_CREDIT_CARD_PAYCOMET);
  const enableTicketRestaurantCard = useFlag(
    LaunchDarklyFlag.ENABLE_TICKET_RESTAURANT_CARD_PAYCOMET
  );
  const onlySendPostalCode = useFlag(LaunchDarklyFlag.SEND_POSTAL_CODE_ONLY_FOR_FIRST_DATA_PAYMENT);
  const enableZeroPricedOrderWithoutPayment = useFlag(
    LaunchDarklyFlag.ENABLE_ZERO_PRICED_ORDER_WITHOUT_PAYMENT
  );
  const enablePaymentOnDeliveryCard = useFlag(
    LaunchDarklyFlag.ENABLE_PAYMENT_ON_DELIVERY_CARD_PAYCOMET
  );
  const enableBizum = useFlag(LaunchDarklyFlag.ENABLE_BIZUM_PAYCOMET);
  const enableWaylet = useFlag(LaunchDarklyFlag.ENABLE_WAYLET_PAYCOMET);
  const enableApplePayLink = useFlag(LaunchDarklyFlag.ENABLE_APPLE_PAY_PAYCOMET);
  const enableMBWay = useFlag(LaunchDarklyFlag.ENABLE_MBWAY_PAYCOMET);
  const [redirectResult, setRedirectResult] = useState<string>();
  const [redirectData, setRedirectData] = useState<RedirectData>();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [fireDeleteAccountMutation] = useDeleteAccountMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: UserAccountsDocument, variables: { feCountryCode } }],
  });
  const [firePrepaidMergeMutation] = usePrepaidsMergeMutation({
    refetchQueries: [{ query: UserAccountsDocument, variables: { feCountryCode } }],
  });

  const {
    threeDSActiveFlow,
    setThreeDSActiveFlow,
    threeDSTransactionId,
    setThreeDSTransactionId,
    threeDSIframeContent,
    setThreeDSIframeContent,
    threeDSAcsUrl,
    setThreeDSAcsUrl,
    threeDSChallengeRequest,
    setThreeDSChallengeRequest,
    isChallengeRequest,
    isThreeDSMethodFlow,
    cleanThreeDSFlow,
    setThreeDSChallengeTokenResponse,
    threeDSChallengeTokenResponse,
  }: UseThreeDS = useThreeDS();

  const fdAccessTokenFirstRequest = useRef<string | null>(null);

  const {
    data: userAccountsData,
    loading: accountsLoading,
    refetch: refetchPaymentMethods,
  } = useUserAccountsQuery({
    variables: { feCountryCode },
    // Apollo client loading state gets stuck: https://github.com/apollographql/react-apollo/issues/3425
    // Temporary fix while we wait for a stable 3.0.0 version
    fetchPolicy: 'cache-and-network',
    skip: !user && !isGuestAuthenticated,
  });

  const { cognitoId, thLegacyCognitoId, details } = user || {};
  const { defaultPaymentAccountId: defaultPrepaidPaymentMethodId, defaultReloadAmt } =
    details || {};

  const normalizeDefaultAccountIdentifier = (defaultAccountIdentifier: string | null) =>
    // the default account should not be PAYPAL. Paypal will cause an issue on payment method list bc it requires a click to open the paypal link
    defaultAccountIdentifier === CartPaymentCardType.PAYPAL ? null : defaultAccountIdentifier;

  // note: defaultFdAccountId is deprecated. use defaultAccountIdentifier if present
  const defaultAccountIdentifier = normalizeDefaultAccountIdentifier(
    details?.defaultAccountIdentifier ?? details?.defaultFdAccountId ?? null
  );

  const [checkoutPaymentMethodId, setCheckoutPaymentMethodId] = useState('');
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState('');
  const [defaultReloadPaymentMethodId, setDefaultReloadPaymentMethodId] = useState('');
  const [prepaidReloadPaymentMethodId, setPrepaidReloadPaymentMethodId] = useState('');
  const [paymentMethodHasBeenInit, setPaymentMethodHasBeenInit] = useState(false);
  const [isFreeOrderPayment, setIsFreeOrderPayment] = useState(false);
  interface SavePaymentMethodContext {
    paymentProcessor?: string;
    chaseProfileId?: string | null;
    accountIdentifier?: string;
    message?: string;
  }

  const saveNewPaymentMethodEvent = useCallback(
    (success: boolean, message?: string, context: SavePaymentMethodContext = {}) => {
      /// Log to cdp provider
      cdp.trackEvent({
        name: CustomEventNames.SAVE_NEW_PAYMENT_METHOD,
        type: EventTypes.Other,
        attributes: {
          Response: success ? 'Successful' : 'Failure',
          'Response Description': success ? 'Successful' : message,
          'Payment Processor': context.paymentProcessor,
        },
      });
      // Log to DataDog
      dataDogLogger({
        message: success ? 'Payment method added' : 'Error adding payment method',
        context: context as Context,
        status: success ? StatusType.info : StatusType.error,
      });
    },
    [cdp]
  );

  const deletePaymentMethodEvent = useCallback(
    (success: boolean, message?: string) => {
      cdp.trackEvent({
        name: CustomEventNames.DELETE_PAYMENT_METHOD,
        type: EventTypes.Other,
        attributes: {
          Response: success ? 'Successful' : 'Failure',
          'Response Description': success ? 'Successful' : message,
        },
      });
    },
    [cdp]
  );

  const logUserPaymentIdentity = useCallback(
    (accountIdentifier: string) => {
      if (!user || !details) {
        return;
      }
      const selectedMethod = paymentMethods.find(
        method =>
          method.accountIdentifier === accountIdentifier || method.fdAccountId === accountIdentifier
      );
      if (!selectedMethod) {
        return;
      }
      let ccToken;
      if (selectedMethod.credit) {
        ccToken = selectedMethod.credit.panToken;
      } else if (selectedMethod.prepaid) {
        ccToken = selectedMethod.prepaid.cardNumber;
      }

      const customerid = getCustomerIdForCRMStack(cognitoId, thLegacyCognitoId);
      cdp.updateUserIdentities({
        customerid,
        email: removeEmailPrefix(details.email),
        ccToken,
      });
    },
    [cdp, cognitoId, thLegacyCognitoId, details, paymentMethods, user]
  );

  const setAndLogCheckoutPaymentMethodId = useCallback(
    (fdAccountId: string) => {
      logUserPaymentIdentity(fdAccountId);
      setCheckoutPaymentMethodId(fdAccountId);
    },
    [logUserPaymentIdentity, setCheckoutPaymentMethodId]
  );

  const isFreeOrder = useCallback(
    (totalCents: number) => {
      const allowFreeOrderWithoutPayment = totalCents === 0 && enableZeroPricedOrderWithoutPayment;
      setIsFreeOrderPayment(allowFreeOrderWithoutPayment);
      return allowFreeOrderWithoutPayment;
    },
    [enableZeroPricedOrderWithoutPayment]
  );

  const setAndLogDefaultReloadPaymentMethodId = useCallback(
    (accountId: string) => {
      setPrepaidReloadPaymentMethodId(accountId);
      setDefaultReloadPaymentMethodId(accountId);
      if (user) {
        updateUserInfo({
          ...user.details,
          defaultReloadPaymentMethodId: accountId,
        });
      }
    },
    [user, updateUserInfo]
  );

  const updatePaymentMethodsState = useCallback(
    (
      validDefaultPaymentMethodId: string,
      validDefaultReloadPaymentMethodId: string,
      shouldMuteUpdateUserInfoErrors?: boolean
    ) => {
      /*
      call updateMe query only if user has a invalid defaultAccountIdentifier
      or if it's different than the validDefaultPaymentMethodId returned by the getPaymentMethodsState hook
      */
      const accounts = userAccountsData?.userAccounts?.accounts || [];
      const shouldUpdateUser =
        !user?.details?.defaultAccountIdentifier ||
        (validDefaultPaymentMethodId !== user?.details?.defaultAccountIdentifier &&
          accounts.length > 0);

      setDefaultPaymentMethodId(validDefaultPaymentMethodId);
      setCheckoutPaymentMethodId(validDefaultPaymentMethodId);
      setPrepaidReloadPaymentMethodId(validDefaultReloadPaymentMethodId);
      setDefaultReloadPaymentMethodId(validDefaultReloadPaymentMethodId);

      if (user && shouldUpdateUser) {
        // Update the checkout and reload payment method when adding a new CC
        updateUserInfo(
          {
            ...user.details,
            defaultCheckoutPaymentMethodId: validDefaultPaymentMethodId,
            defaultReloadPaymentMethodId: validDefaultReloadPaymentMethodId,
          },
          shouldMuteUpdateUserInfoErrors
        );
      }
    },
    [updateUserInfo, user]
  );

  const setAndLogDefaultPaymentMethodId = useCallback(
    (accountId: string, isAddingNewCc?: boolean) => {
      logUserPaymentIdentity(accountId);
      setDefaultPaymentMethodId(accountId);
      // Set the checkout payment method when the user change its default one
      setCheckoutPaymentMethodId(accountId);

      if (user) {
        // Update the checkout and reload payment method when adding a new CC
        updateUserInfo({
          ...user.details,
          defaultCheckoutPaymentMethodId: accountId,
          ...(isAddingNewCc ? { defaultReloadPaymentMethodId: accountId } : {}),
        });
      }
    },
    [logUserPaymentIdentity, setDefaultPaymentMethodId, user, updateUserInfo]
  );

  const getEncryptionDetails = useCallback(async () => {
    const encryptionDetailsResponse = await getEncryptionDetailsMutation();

    if (!encryptionDetailsResponse.data) {
      throw new Error('Missing encryption details');
    }

    return encryptionDetailsResponse.data.encryptionDetails;
  }, [getEncryptionDetailsMutation]);

  const clearPaymentMethods = useCallback(() => {
    setPaymentMethods([]);
    setHasGetPaymentMethodsError(false);
    setLoading(false);
  }, []);

  const getPaymentMethods = useCallback(async () => {
    try {
      if (cognitoId) {
        await refetchPaymentMethods();
      }
    } catch (error) {
      logger.error({
        error,
        message: 'Error getting payment methods',
      });
      clearPaymentMethods();
      setHasGetPaymentMethodsError(true);
      openErrorDialog({
        message: formatMessage({ id: 'paymentLoadingError' }),
        modalAppearanceEventMessage: `Error: Fetching Payment Methods Failure - ${error.message}`,
        error,
      });
    }
  }, [refetchPaymentMethods, clearPaymentMethods, openErrorDialog, formatMessage, cognitoId]);

  /**
   * set up a bunch of state related to a customer's payment accounts and methods
   * @param {IPaymentMethod[]} accounts - list of ways a customer could pay (prepaid, credit card, mobile wallets etc)
   * @param {string} paymentMethodId - attempt to use this as the default payment method
   * @param {string} prepaidPaymentMethodId - attempt to use this as the default prepaid payment method
   */
  const initPaymentMethods = useCallback(
    (
      { accounts, paymentMethodId, prepaidPaymentMethodId }: IInitPaymentMethods,
      shouldMuteUpdateUserInfoErrors?: boolean
    ) => {
      // Get valid payment methods
      const {
        availablePaymentMethodList,
        validDefaultPaymentMethodId,
        validDefaultReloadPaymentMethodId,
        allPaymentList,
      } = getPaymentMethodsState({
        paymentMethodId,
        prepaidPaymentMethodId,
        accounts,
        canUseApplePay,
        enableBlik,
        enableCashPayment,
        enablePayPal,
        enableSodexoVoucher,
        enableChequeGourmetVoucher,
        enableTicketRestaurantVoucher,
        enablePayPalPaycomet,
        enableSodexo,
        enableChequeGourmet,
        enableTicketRestaurantCard,
        applePayCardDetails,
        canUseGooglePay,
        googlePayCardDetails,
        paymentMethodHasBeenInit,
        enableSaveCard: true,
        enablePaymentOnDeliveryCard,
        enableBizum,
        enableWaylet,
        enableApplePayLink,
        enableMBWay,
        // TODO: This parameter/flag is a temporary safeguard, after 1 month in production this should be removed and the final value should always be false.
        enableFirstValidPaymentMethodIdFoundAsDefault,
      });

      // Setup the payment methods into a valid state
      setPaymentMethods(availablePaymentMethodList);
      setAllPaymentMethods(allPaymentList);
      updatePaymentMethodsState(
        validDefaultPaymentMethodId,
        validDefaultReloadPaymentMethodId,
        shouldMuteUpdateUserInfoErrors
      );
    },
    [
      canUseApplePay,
      enableBlik,
      enableCashPayment,
      enablePayPal,
      enableSodexoVoucher,
      enableChequeGourmetVoucher,
      enablePayPalPaycomet,
      enableSodexo,
      enableChequeGourmet,
      applePayCardDetails,
      canUseGooglePay,
      googlePayCardDetails,
      paymentMethodHasBeenInit,
      enablePaymentOnDeliveryCard,
      updatePaymentMethodsState,
      enableBizum,
      enableWaylet,
      enableApplePayLink,
      enableTicketRestaurantCard,
      enableTicketRestaurantVoucher,
      enableMBWay,
      // TODO: This parameter/flag is a temporary safeguard, after 1 month in production this should be removed and the final value should always be false.
      enableFirstValidPaymentMethodIdFoundAsDefault,
    ]
  );

  useEffect(() => {
    if (!user) {
      clearPaymentMethods();
      return;
    }
    if (userAccountsData) {
      const accounts: IPaymentMethod[] =
        (userAccountsData.userAccounts &&
          userAccountsData.userAccounts.accounts &&
          userAccountsData.userAccounts.accounts.filter<IPaymentMethod>(
            (account): account is IPaymentMethod =>
              !!account && (!!account.fdAccountId || !!account.accountIdentifier)
          )) ||
        [];

      setHasGetPaymentMethodsError(false);

      if (accounts.length) {
        storePrepaidCard({ accounts });
      }
      setLoading(false);
    }
  }, [clearPaymentMethods, user, userAccountsData]);

  const { reloadPrepaidsCardMutation, loading: prepaidLoading } = usePrepaidsReload({
    openErrorDialog,
  });

  const getPrepaidPaymentMethod = useCallback(() => {
    return paymentMethods.find(method => Boolean(method.prepaid)) || null;
  }, [paymentMethods]);

  const getBalanceFromPaymentMethods = (prepaidPaymentMethod: IPaymentMethod) => {
    if (prepaidPaymentMethod && prepaidPaymentMethod.prepaid) {
      if (prepaidPaymentMethod.prepaid.feFormattedCurrentBalance) {
        return prepaidPaymentMethod.prepaid.feFormattedCurrentBalance;
      } else if (prepaidPaymentMethod.prepaid.currentBalance) {
        return prepaidPaymentMethod.prepaid.currentBalance;
      }
    }
    return 0;
  };

  const getPrepaidCardNumber = useCallback(() => {
    const prepaidPaymentMethod = getPrepaidPaymentMethod();

    if (prepaidPaymentMethod && prepaidPaymentMethod.prepaid) {
      // add spaces between every 4 characters in the card number
      return (prepaidPaymentMethod.prepaid.cardNumber.match(/.{1,4}/g) || []).join(' ');
    }
    return null;
  }, [getPrepaidPaymentMethod]);

  const deletePaymentMethod = useCallback(
    async (accountIdentifier: string) => {
      try {
        const { data } = await fireDeleteAccountMutation({
          variables: { input: { accountIdentifier } },
        });
        if (data?.deleteAccount) {
          // Strip the paymentMethods list from the deleted CC
          const updatedPaymentMethods = paymentMethods.filter(
            method => method.accountIdentifier !== accountIdentifier
          );
          // If the default payment method is the deleted one, reset the payment method using the 1st in the updated array
          const paymentMethodIdAfterDelete =
            defaultAccountIdentifier !== accountIdentifier
              ? defaultAccountIdentifier
              : updatedPaymentMethods[0]?.accountIdentifier;

          // Re-init the payment methods state to make sure we're in a fully working state after deleting a CC
          initPaymentMethods({
            accounts: updatedPaymentMethods,
            paymentMethodId: paymentMethodIdAfterDelete || '',
            prepaidPaymentMethodId: paymentMethodIdAfterDelete || '',
          });

          deletePaymentMethodEvent(true);
        }
      } catch (error) {
        logger.error({ error, message: 'Error deleting payment method' });
        deletePaymentMethodEvent(false, error.message);
        openErrorDialog({
          message: formatMessage({ id: 'paymentDeletingError' }),
          modalAppearanceEventMessage: 'Error: Deleting Payment Method Failure',
          error,
        });
      }
    },
    [
      fireDeleteAccountMutation,
      paymentMethods,
      defaultAccountIdentifier,
      initPaymentMethods,
      deletePaymentMethodEvent,
      logger,
      openErrorDialog,
      formatMessage,
    ]
  );

  const paymentProcessor = userAccountsData?.userAccounts?.paymentProcessor;
  const isVrPayment = paymentProcessor === PaymentProcessor.VRPAYMENT;
  const isPayMark = paymentProcessor === PaymentProcessor.PAYMARK;
  const isPaycomet = paymentProcessor === PaymentProcessor.PAYCOMET;
  const isCheckoutDotCom = paymentProcessor === PaymentProcessor.CHECKOUTDOTCOM;
  const isCybersource = paymentProcessor === PaymentProcessor.CYBERSOURCE;
  const isEvertec = paymentProcessor === PaymentProcessor.EVERTEC;
  const isFirstpay = paymentProcessor === PaymentProcessor.FIRSTPAY;
  const isHostedPage =
    isVrPayment ||
    isPayMark ||
    isFirstpay ||
    isPaycomet ||
    isCheckoutDotCom ||
    isCybersource ||
    isEvertec;
  const isAdyen = !isHostedPage && paymentProcessor === PaymentProcessor.ADYEN;
  const isFirstData = !isHostedPage && paymentProcessor === PaymentProcessor.FIRSTDATA;
  const isOrbital = !isHostedPage && paymentProcessor === PaymentProcessor.ORBITAL;

  // Expected value that is applied to hidden form fields for payment method according to payment processor
  const hiddenFieldValue = hiddenFieldValueByPaymentProcessor[paymentProcessor || ''] || null;

  const transformPaymentValues = useCallback(
    ({
      paymentValues,
      paymentFieldVariations = defaultPaymentFieldVariation,
    }: {
      paymentValues: IPaymentState | IAdyenPaymentState;
      paymentFieldVariations?: PaymentFieldVariations;
    }): IPaymentPayload => {
      let expiryDate = null;
      let { cardNumber } = paymentValues;

      if (isAdyen) {
        expiryDate = {
          month: (paymentValues as IAdyenPaymentState).encryptedExpiryMonth,
          year: (paymentValues as IAdyenPaymentState).encryptedExpiryYear,
        };
      } else {
        cardNumber = sanitizeNumber(paymentValues.cardNumber);
        const { expiryMonth, expiryYear } = splitExpiry(paymentValues.expiry ?? '');
        expiryDate = paymentValues.expiry
          ? {
              month: expiryMonth,
              year: expiryYear,
            }
          : null;
      }
      const country = paymentValues.billingCountry
        ? getIso2({ iso2: paymentValues.billingCountry })
        : null;
      const postalCode =
        paymentValues.billingCountry === ISOs.GBR
          ? parseUkPostCode(paymentValues.billingZip)
          : sanitizeAlphanumeric(paymentValues.billingZip);
      return {
        billingAddress: {
          country: paymentFieldVariations.country ? country : hiddenFieldValue,
          postalCode: paymentFieldVariations.zip ? postalCode : hiddenFieldValue,
          unitNumber: paymentFieldVariations.addressLine2
            ? paymentValues.billingApt
            : hiddenFieldValue,
          locality: paymentFieldVariations.city ? paymentValues.billingCity : hiddenFieldValue,
          region: paymentFieldVariations.state ? paymentValues.billingState : hiddenFieldValue,
          streetAddress: paymentFieldVariations.addressLine1
            ? paymentValues.billingStreetAddress
            : hiddenFieldValue,
        },
        cardNumber,
        cardType: paymentValues.cardType,
        expiryDate,
        nameOnCard: paymentValues.nameOnCard,
        securityCode: paymentValues.cvv,
      };
    },
    [isAdyen, hiddenFieldValue]
  );

  const addPaymentMethod = useCallback(
    async (
      paymentValues: IPaymentPayload,
      { skipErrorDialogOnError = false }: IAddPaymentMethodOptions = {},
      threeDSChallengeToken?: string | null
    ) => {
      const {
        billingAddress,
        securityCode,
        cardNumber,
        nameOnCard: fullName,
        expiryDate,
      } = paymentValues;
      const { accountToDelete, ...cleanedPaymentValues } = paymentValues;

      if (!loading) {
        setLoading(true);
      }

      try {
        // If we're re-vaulting, delete the payment method before adding the new one
        if (isAdyen && accountToDelete) {
          await deletePaymentMethod(accountToDelete);
        }

        let addAccount;
        let chaseProfileId;
        let accountIdentifier;

        // Handles adding a payment method when the payment processor is Adyen
        if (isAdyen) {
          const addAccountResponse = await addCreditAccountMutation({
            variables: {
              input: {
                adyenInput: {
                  encryptedPayload: {
                    encryptedCN: cardNumber,
                    encryptedEM: expiryDate?.month || '',
                    encryptedEY: expiryDate?.year || '',
                    encryptedSC: securityCode ?? '',
                  },
                },
                billingAddress,
                fullName,
              },
            },
          });

          if (!addAccountResponse.data?.addCreditAccount.accountIdentifier) {
            throw new Error('Add Account Failure');
          }

          addAccount = addAccountResponse.data.addCreditAccount;
          accountIdentifier = addAccount.accountIdentifier ?? '';
        } else if (isOrbital) {
          // If we're re-vaulting, delete the payment method before adding the new one
          if (accountToDelete) {
            await deletePaymentMethod(accountToDelete);
          }

          const orbitalValues = await encryptOrbitalCard(cardNumber, securityCode ?? '');
          const orbitalInput = {
            encryptedCardNum: orbitalValues.cryptCard,
            encryptedCvv: orbitalValues.cryptCvv,
            expiryMonth: paymentValues.expiryDate?.month ?? '',
            expiryYear: paymentValues.expiryDate?.year ?? '',
            pieFormat: orbitalValues.pieFormat,
            pieMode: orbitalValues.mode,
            piePhaseId: String(orbitalValues.phase),
            pieIntegrityCheck: orbitalValues.integrityCheck,
            pieSubscriberId: orbitalValues.subscriberId,
            pieKeyID: orbitalValues.keyId,
            cardBrand: paymentValues.cardType.toLowerCase(),
            bin: cardNumber.substring(0, 6),
          };

          let addAccountResponse: any = {};
          try {
            addAccountResponse = await addCreditAccountMutation({
              variables: {
                input: {
                  billingAddress,
                  orbitalInput,
                  fullName: paymentValues.nameOnCard,
                },
              },
            });
          } catch (error) {
            // No 3DS for Orbital, so throw the error similar to what is done for FD
            throw error;
          }

          // Assertion of minimum data for add account.
          // accountIdentifier and orbitalIdentifier should be the same
          if (
            !addAccountResponse.data?.addCreditAccount.accountIdentifier &&
            !addAccountResponse.data?.addCreditAccount.orbitalIdentifier
          ) {
            throw new Error('Add Account Failure');
          }

          addAccount = addAccountResponse.data.addCreditAccount;
          accountIdentifier = addAccount.accountIdentifier ?? addAccount.fdAccountId ?? '';
        } else if (isFirstpay) {
          const addAccountResponse = await addCreditAccountMutation({
            variables: {
              input: {
                firstpayInput: {
                  storedPaymentMethodId: paymentValues.cardNumber ?? '',
                  bin: paymentValues.firstpay?.bin ?? '',
                  last4: paymentValues.firstpay?.last4 ?? '',
                  cardBrand: paymentValues.cardType.toLowerCase(),
                  expiryMonth: paymentValues.expiryDate?.month ?? '',
                  expiryYear: paymentValues.expiryDate?.year ?? '',
                },
                billingAddress,
                fullName,
              },
            },
          });

          if (!addAccountResponse.data?.addCreditAccount.accountIdentifier) {
            throw new Error('Add Account Failure');
          }

          addAccount = addAccountResponse.data.addCreditAccount;
          accountIdentifier = addAccount.accountIdentifier ?? '';
        } else {
          // Handles adding a payment method when the payment processor is First Data
          const ccBin = cardNumber.slice(0, 6);

          let firstDataInput: IAddAccountInput = { ccBin: '', fdAccessToken: '', fdNonce: '' };
          let addAccountResponse: any = {};

          /**
           * METHOD FLOW
           * this means that we are in a frictionless flow and we need to resume
           * the add credit card account with some previous data we take in the
           * last execution of the current flow.
           * CHALLENGE FLOW
           * Little bit different to METHOD FLOW where it require a new parameter
           * called challengeResponse and transactionId too.
           **/
          if (
            threeDSActiveFlow === ThreeDSType.METHOD ||
            threeDSActiveFlow === ThreeDSType.CHALLENGE
          ) {
            if (!fdAccessTokenFirstRequest.current || !threeDSTransactionId) {
              throw new Error('Error resolving 3DS');
            }
            firstDataInput = {
              fdAccessToken: fdAccessTokenFirstRequest.current,
              fdNonce: 'no-usage',
              ccBin,
              chaseProfileId: null,
              threeDSOptions: {
                transactionId: threeDSTransactionId,
                challengeResponse: threeDSChallengeToken,
              },
            };
          } else {
            // If we're re-vaulting, delete the payment method before adding the new one
            if (accountToDelete) {
              await deletePaymentMethod(accountToDelete);
            }

            const {
              fdPublicKey,
              fdApiKey,
              fdAccessToken,
              fdCustomerId, // Necessary second request
            } = await getEncryptionDetails();

            if (!fdAccessTokenFirstRequest.current) {
              fdAccessTokenFirstRequest.current = fdAccessToken;
            }

            const nonceResponse = await getNonce(
              cleanedPaymentValues,
              fdPublicKey,
              fdApiKey,
              fdAccessToken,
              fdCustomerId,
              details?.isoCountryCode as ISOs | undefined,
              onlySendPostalCode
            );
            const { token } = await nonceResponse.json();
            const fdNonce = token.tokenId;

            firstDataInput = {
              fdAccessToken,
              fdNonce,
              ccBin,
              chaseProfileId,
            };
          }

          try {
            addAccountResponse = await addCreditAccountMutation({
              variables: {
                input: {
                  billingAddress,
                  firstDataInput,
                  fullName,
                },
              },
            });
          } catch (error) {
            // Possible ThreeDS exception detected
            const graphQLErrorsData: ThreeDSData | null = handleThreeDSPayment(error);

            if (graphQLErrorsData) {
              const currentFlow = graphQLErrorsData?.type;

              const isThreeDSMethodCurrentFlow = currentFlow === ThreeDSType.METHOD;
              if (isThreeDSMethodCurrentFlow) {
                if (graphQLErrorsData.transactionId && graphQLErrorsData.iframeContent) {
                  setThreeDSActiveFlow(currentFlow);
                  setThreeDSTransactionId(graphQLErrorsData.transactionId);
                  setThreeDSIframeContent(graphQLErrorsData.iframeContent);
                }
                throw new ThreeDSMethodError();
              }

              const isThreeDSChallengeCurrentFlow = currentFlow === ThreeDSType.CHALLENGE;
              if (isThreeDSChallengeCurrentFlow) {
                if (
                  graphQLErrorsData.acsUrl &&
                  graphQLErrorsData.challengeRequestToken &&
                  graphQLErrorsData.transactionId
                ) {
                  setThreeDSActiveFlow(currentFlow);
                  setThreeDSAcsUrl(graphQLErrorsData.acsUrl);
                  setThreeDSChallengeRequest(graphQLErrorsData.challengeRequestToken);
                  setThreeDSTransactionId(graphQLErrorsData.transactionId);
                }
                throw new ThreeDSChallengeError();
              }
            }

            /**
             * As the error is not one that are we expecting, we just throw de current error
             * to let everything work we the next catch.
             */
            cleanThreeDSFlow();
            throw error;
          }

          // Assertion of minimun data for add account.
          if (
            !addAccountResponse.data?.addCreditAccount.accountIdentifier &&
            !addAccountResponse.data?.addCreditAccount.fdAccountId
          ) {
            throw new Error('Add Account Failure');
          }

          addAccount = addAccountResponse.data.addCreditAccount;
          const cardType = FirstDataAcceptedCardsToCardType[addAccount.credit.cardType];

          if (cardType) {
            addAccount.credit.cardType = cardType;
          }

          accountIdentifier = addAccount.accountIdentifier ?? addAccount.fdAccountId ?? '';
        }

        saveNewPaymentMethodEvent(true, undefined, { chaseProfileId, accountIdentifier });

        // Re-init the payment methods state to make sure we're in a fully working state after adding a CC
        initPaymentMethods({
          accounts: [...paymentMethods, addAccount as IPaymentMethod],
          paymentMethodId: accountIdentifier,
          prepaidPaymentMethodId: accountIdentifier,
        });

        // When a user adds a CC, set the Checkout and Reload Default payment method to the newly added one
        await setAndLogDefaultPaymentMethodId(accountIdentifier, true);

        cleanThreeDSFlow();

        return accountIdentifier;
      } catch (error) {
        logger.error({
          error,
          message: 'Error adding payment method',
        });
        saveNewPaymentMethodEvent(false, error.message, {
          chaseProfileId: undefined,
          accountIdentifier: undefined,
          message: error.message,
        });
        const isTreeDSError =
          error instanceof ThreeDSMethodError || error instanceof ThreeDSChallengeError;
        if (!skipErrorDialogOnError && !isTreeDSError) {
          const statusCode: number | undefined = error.graphQLErrors?.find(
            (graphQLError: GraphQLError) => Number.isInteger(graphQLError?.extensions?.statusCode)
          )?.extensions?.statusCode;
          openErrorDialog({
            message:
              statusCode === HttpErrorCodes.TooManyRequests
                ? formatMessage({ id: 'tooManyAddAccountAttempts' })
                : formatMessage({ id: 'paymentAddingError' }),
            modalAppearanceEventMessage: 'Error: Adding Payment Method Failure',
            error,
          });
        }
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [
      loading,
      isAdyen,
      isOrbital,
      isFirstpay,
      saveNewPaymentMethodEvent,
      initPaymentMethods,
      paymentMethods,
      setAndLogDefaultPaymentMethodId,
      cleanThreeDSFlow,
      deletePaymentMethod,
      addCreditAccountMutation,
      user,
      logger,
      threeDSActiveFlow,
      threeDSTransactionId,
      getEncryptionDetails,
      details,
      onlySendPostalCode,
      setThreeDSActiveFlow,
      setThreeDSTransactionId,
      setThreeDSIframeContent,
      setThreeDSAcsUrl,
      setThreeDSChallengeRequest,
      openErrorDialog,
      formatMessage,
    ]
  );

  const reloadPrepaidCard = useCallback(
    async (reloadInfo: IReloadPrepaidCard) => {
      try {
        const { data } = await reloadPrepaidsCardMutation(reloadInfo as IPrepaidsReload);

        return data?.prepaidsReload?.currentBalance;
      } catch (error) {
        logger.error({
          error,
          message: 'Error reloading prepaid card',
        });
        return undefined;
      }
    },
    [logger, reloadPrepaidsCardMutation]
  );

  const mergePrepaidCardBalances = async ({
    destinationFdAccountId,
    sourceCardNumber,
    sourcePin,
  }: IPrepaidsMergeInput) => {
    if (!loading) {
      setLoading(true);
    }

    try {
      const { data: wrapper } = await firePrepaidMergeMutation({
        variables: {
          input: {
            destinationFdAccountId,
            sourceCardNumber: sourceCardNumber.replace(/\s/g, ''),
            sourcePin,
          },
        },
      });

      const data = wrapper!.prepaidsMerge;

      await getPaymentMethods();

      // Set the newly added GC, or the desitination GC as the checkout payment method.
      setCheckoutPaymentMethodId(data.fdAccountId || destinationFdAccountId || '');

      return data;
    } finally {
      setLoading(false);
    }
  };

  const checkoutPaymentMethod = paymentMethods.find(
    ({ accountIdentifier, fdAccountId }) =>
      accountIdentifier === checkoutPaymentMethodId || fdAccountId === checkoutPaymentMethodId
  );

  useEffect(
    () => {
      // Initialize the payment method when the app loads

      const accounts = (userAccountsData?.userAccounts?.accounts || []).map(
        userAccountsToPaymentMethods
      );
      if (accounts) {
        // First time running, set the paymentMethodHasBeenInit to true
        // This prevent re-init when the user's accounts change
        /**
         * Unlike Apple Pay and Google Pay, the cash method can be a default payment method
         * so add it before `initPaymentMethods` runs so it can be sorted & stored as the default method in FE state
         */
        const accountsMaybeWithCash = enableCashPayment ? [...accounts, cashAccount] : accounts;
        if (!paymentMethodHasBeenInit) {
          setPaymentMethodHasBeenInit(true);
          // When we have the user's payment method list, initialize the payment methods state
          initPaymentMethods(
            {
              accounts: accountsMaybeWithCash,
              paymentMethodId: defaultAccountIdentifier || '',
              prepaidPaymentMethodId: defaultPrepaidPaymentMethodId || '',
            },
            true
          );
        } else {
          /**
           * If the payment methods has been initialized, use checkoutPaymentMethodId and prepaidReloadPaymentMethodId
           * to keep the payment method state in a stable state.
           * Unless the customer has cash as a default payment. Due to feature flag loading time,
           * cash becomes in this branch if statement rather than above, so `defaultAccountIdentifier`
           * needs to be passed as `paymentMethodId` again for the Accounts/Payment Methods screen to show the correct
           * default account.
           */

          const canUseCashAsDefaultPaymentMethod =
            defaultAccountIdentifier === CASH_ACCOUNT_IDENTIFIER && enableCashPayment;

          initPaymentMethods(
            {
              accounts: accountsMaybeWithCash,
              paymentMethodId: canUseCashAsDefaultPaymentMethod
                ? defaultAccountIdentifier || ''
                : checkoutPaymentMethodId,
              prepaidPaymentMethodId: prepaidReloadPaymentMethodId,
            },
            true
          );
        }
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      cognitoId,
      enableCashPayment,
      isFreeOrderPayment,
      defaultAccountIdentifier,
      userAccountsData?.userAccounts?.accounts,
      enablePaymentOnDeliveryCard,
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  useEffect(() => {
    getPaymentMethods();
  }, [cognitoId, defaultAccountIdentifier, defaultReloadAmt]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading: loading || accountsLoading || prepaidLoading,
    allPaymentMethods,
    paymentMethods,
    getPaymentMethods,
    getBalanceFromPaymentMethods,
    getPrepaidPaymentMethod,
    getPrepaidCardNumber,
    getEncryptionDetails,
    checkoutPaymentMethod,
    checkoutPaymentMethodId,
    setCheckoutPaymentMethodId: setAndLogCheckoutPaymentMethodId,
    defaultPaymentMethodId,
    defaultReloadPaymentMethodId,
    setDefaultPaymentMethodId: setAndLogDefaultPaymentMethodId,
    setDefaultReloadPaymentMethodId: setAndLogDefaultReloadPaymentMethodId,
    setPrepaidReloadPaymentMethodId,
    prepaidReloadPaymentMethodId,
    addPaymentMethod,
    deletePaymentMethod,
    redirectResult,
    setRedirectResult,
    redirectData,
    setRedirectData,
    isPending,
    setIsPending,
    reloadPrepaidCard,
    mergePrepaidCardBalances,
    hasGetPaymentMethodsError,
    canUseApplePay,
    canUseGooglePay,
    paymentProcessor,
    isAdyen,
    isCheckoutDotCom,
    isCybersource,
    isEvertec,
    isFirstData,
    isFirstpay,
    isVrPayment,
    isPayMark,
    isPaycomet,
    isHostedPage,
    isOrbital,
    threeDSAcsUrl,
    threeDSActiveFlow,
    threeDSChallengeRequest,
    threeDSIframeContent,
    threeDSTransactionId,
    isChallengeRequest,
    isThreeDSMethodFlow,
    transformPaymentValues,
    cleanThreeDSFlow,
    setThreeDSChallengeTokenResponse,
    threeDSChallengeTokenResponse,
    isFreeOrder,
    isFreeOrderPayment,
  };
};

export default usePayment;

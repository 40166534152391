import React, { useCallback, useMemo, useRef } from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Button } from 'components/mobile-web-navigation-bar/styled';
import { View } from 'components/view';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { useFeatureNavigation } from 'hooks/use-feature-navigation';
import { useOrderHref } from 'hooks/use-order-href';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import { useLocationContext, useMatch } from 'state/location';
import { useOrderContext } from 'state/order';
import { useScrollContext } from 'state/scroll';
import { useStoreContext } from 'state/store';
import { isRecentItemsRoute, routes } from 'utils/routing';

import CheckoutButtonLink from './checkout-button-link';
import { MobileWebNavigationBarSkeleton } from './mobile-web-navigation-bar.skeleton';
import { MobileWebNavigationBorder } from './mobile-web-navigation-border';
import { getNavLinks } from './nav-links';
import { NavigationMobileTab } from './types';
import { generateNavigationMobileTabs } from './utils-sanity';

const MobileNavInner = styled(View)`
  position: relative;
`;

const MobileWebNavigationContainer = styled(View)`
  justify-content: space-around;
  flex-direction: row;
  background-color: ${p => p.theme.token('bottom-nav-bar-background-color')};
`;

const NavButton = styled(Button)`
  flex: 1 1 0;
  padding-block-start: 0.1rem;
  padding-block-end: 0.2rem;
  padding-inline: 0;
`;

interface IMobileCheckoutButtonProps {
  checkoutRoute: string;
  enableBottomService: boolean;
}

const MobileCheckoutButton: React.FC<IMobileCheckoutButtonProps> = ({
  checkoutRoute,
  enableBottomService,
}) => {
  const baseRouteMatch = useMatch(`${routes.base}*`);
  const storeLocatorMatch = useMatch(`${routes.storeLocator}/*`);
  const menuMatch = useMatch(`${routes.menu}/*`);
  const cartMatch = useMatch(`${routes.cart}/*`);
  const orderConfirmationMatch = useMatch(`${routes.orderConfirmation}/*`);
  const order = useOrderContext();
  const cartButton = useRef<HTMLAnchorElement>(null);
  // Do not show checkout button when bottom service mode is enabled
  if (enableBottomService) {
    return null;
  }
  if (!baseRouteMatch) {
    return null;
  }
  // We don't want to show the checkout cart on the store-locator page
  if (storeLocatorMatch) {
    return null;
  }

  if (menuMatch) {
    const menuObjectId = menuMatch.params['*'];
    if (menuObjectId && !menuObjectId!.startsWith('section')) {
      return null;
    }
  }

  if ((order.cartEntries.length && !cartMatch) || orderConfirmationMatch) {
    return (
      <CheckoutButtonLink amount={order.calculateCartTotal()} to={checkoutRoute} ref={cartButton} />
    );
  }
  return null;
};

const MobileWebNavigationBar: React.FC = () => {
  const menuHref = useOrderHref();
  const order: any = useOrderContext();
  const { isStoreOpenAndAvailable } = useStoreContext();
  const enableOffers = useFlag(LaunchDarklyFlag.ENABLE_OFFERS);
  const enableBottomService = useFlag(LaunchDarklyFlag.ENABLE_BOTTOM_SERVICE_MODE);
  const enableStaticMenu = useFlag(LaunchDarklyFlag.ENABLE_STATIC_MENU);
  const enableAccountInFooter = useFlag(LaunchDarklyFlag.ENABLE_ACCOUNT_IN_FOOTER);
  const navbarWithServiceModeSelected = useFlag(LaunchDarklyFlag.NAVBAR_WITH_SERVICE_MODE_SELECTED);

  const { featureNavigationMobile, featureNavigatonMobileWithStore, featureNavigationLoading } =
    useFeatureNavigation();
  const { formatMessage } = useIntl();
  const { trackEvent } = useCdpContext();
  const { location, pathMatches } = useLocationContext();
  const { store } = useStoreContext();

  const cartButton = useRef<HTMLAnchorElement>(null);
  const { flags: LDFlags } = useLDContext();

  const { scrollTo } = useScrollContext();
  const shouldHideNavigationBar = pathMatches([
    routes.signUp,
    routes.signIn,
    routes.confirmOtp,
    routes.createAccount,
  ]);

  const logMobileNavOrderEvent = useCallback(() => {
    trackEvent({ name: CustomEventNames.MOBILE_NAV_ORDER, type: EventTypes.Navigation });
  }, [trackEvent]);

  const navLinks: NavigationMobileTab[] = useMemo(() => {
    if (featureNavigationLoading) {
      return [];
    }

    const sanityNavEnabled = featureNavigationMobile;

    // The Navigation Tabs With Service Mode / Store Selected should only be used when flag is enabled
    // and items are configured in Sanity. Otherwise we should display default menu items
    const shouldDisplayServiceModeMenu =
      navbarWithServiceModeSelected && store._id && featureNavigatonMobileWithStore.length;

    const links = shouldDisplayServiceModeMenu
      ? featureNavigatonMobileWithStore
      : featureNavigationMobile;

    const isLocationInRecentItemsRoute = isRecentItemsRoute(location.pathname);

    return sanityNavEnabled
      ? generateNavigationMobileTabs(links, LDFlags, isLocationInRecentItemsRoute)
      : getNavLinks({
          menuHref,
          formatMessage,
          enableOffers,
          enableStaticMenu,
          enableAccountInFooter,
          logMobileNavOrderEvent,
        });
  }, [
    featureNavigationLoading,
    featureNavigationMobile,
    navbarWithServiceModeSelected,
    store._id,
    featureNavigatonMobileWithStore,
    location.pathname,
    LDFlags,
    menuHref,
    formatMessage,
    enableOffers,
    enableStaticMenu,
    enableAccountInFooter,
    logMobileNavOrderEvent,
  ]);

  useEffectOnUpdates(() => {
    window.setTimeout(() => {
      cartButton.current?.focus();
    }, 100);
  }, [order.cartEntries.length]);

  const onClickNavLink = useCallback(
    (href: string) => {
      if (location.pathname === href) {
        // whenever a mobile nav button is pressed when the route already matches,
        // scroll to the top of the view
        scrollTo({ x: 0, y: 0 });
      }
    },
    [location.pathname, scrollTo]
  );

  const { serviceMode } = order;

  // TODO: @glengal checkoutRoute is not used. Expected? this is potentially a bug
  let checkoutRoute = routes.cart;
  if (!serviceMode) {
    checkoutRoute = routes.serviceMode;
  } else if (!isStoreOpenAndAvailable) {
    checkoutRoute = routes.storeLocator;
  }

  // not showing mobile navigation bar on sign up flow
  if (shouldHideNavigationBar) {
    return null;
  }

  return (
    <MobileWebNavigationBorder data-mediaquery="headerMobile">
      <MobileNavInner>
        <MobileWebNavigationContainer>
          {navLinks?.length ? (
            navLinks.map((item, index) => (
              <NavButton
                key={index}
                onClick={() => item.selectedHrefs[0] && onClickNavLink(item.selectedHrefs[0])}
              >
                <item.Component />
              </NavButton>
            ))
          ) : (
            <MobileWebNavigationBarSkeleton />
          )}
        </MobileWebNavigationContainer>
        {isStoreOpenAndAvailable && (
          <MobileCheckoutButton
            checkoutRoute={checkoutRoute}
            enableBottomService={enableBottomService}
          />
        )}
      </MobileNavInner>
    </MobileWebNavigationBorder>
  );
};

export default MobileWebNavigationBar;

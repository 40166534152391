import React, { FC } from 'react';

import styled from 'styled-components';

import useMediaQuery from 'hooks/use-media-query';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { primitive } from 'styles/constants/primitives';

import { CookieSettings } from './cookie-settings';
import { Row } from './shared';
import { IFooterProps } from './types';
import { useFeatureFooter } from './use-feature-footer';

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  font-family: ${Styles.fontFamily.base};
  font-weight: ${Styles.fontWeight.normal};
  letter-spacing: normal;

  ${Styles.desktopLarge} {
    display: flex;
    width: 100%;
    margin-block-start: -13px;
    z-index: ${Styles.zIndex.top};
  }
`;

const ContentContainer = styled.div`
  background-color: ${primitive.fhs.$houseNavy};
  color: ${Styles.color.white};
  width: 100%;
  padding-block-start: 1rem;
  padding-block-end: 2rem;
  padding-inline: 1rem;
`;

const FooterContent: FC<IFooterProps> = props => {
  const { featureFooter, featureFooterLoading } = useFeatureFooter();
  const isMobile = useMediaQuery('headerMobile');
  const enableMobileFooter = useFlag(LaunchDarklyFlag.ENABLE_MOBILE_FOOTER);

  if (!featureFooter) {
    return null;
  }

  return featureFooterLoading || (isMobile && !enableMobileFooter) ? null : (
    <FooterContainer {...props} role="contentinfo">
      <ContentContainer>
        {featureFooter?.rows?.map((row, index) => row && <Row key={row._key ?? index} {...row} />)}
        <CookieSettings />
      </ContentContainer>
    </FooterContainer>
  );
};

export default FooterContent;

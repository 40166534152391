import React from 'react';

import { useIntl } from 'react-intl';

import { AppSubheader } from 'components/app-subheader';
import { DeliveryBannerContainer } from 'components/delivery-banner';
import { OrderTrackingBannerContainerMaybe } from 'components/order-tracking-banner';
import { TopServiceMode } from 'components/top-service-mode';
import { View } from 'components/view';
import useMediaQuery from 'hooks/use-media-query';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoadingIndicator } from 'state/loading';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';

import { HeaderContainer, NavBar, TopServiceModeContainer } from './styled';
import StyledLoadingIndicator from './styled-loading-indicator';

export const AppHeaderContainer: React.FC<React.PropsWithChildren> = ({ children, ...rest }) => {
  const { formatMessage } = useIntl();
  const { serviceMode, cateringPickupDateTime, deliveryAddress } = useOrderContext();
  const isMobile = useMediaQuery('headerMobile');
  const LoadingIndicator = useLoadingIndicator();
  const { isStoreOpenAndAvailable } = useStoreContext();
  const enableBottomServiceMode = useFlag(LaunchDarklyFlag.ENABLE_BOTTOM_SERVICE_MODE);
  const enableTopServiceMode =
    useFlag(LaunchDarklyFlag.ENABLE_TOP_SERVICE_MODE) && !enableBottomServiceMode;

  return (
    <View data-testid="app-header">
      <HeaderContainer {...rest}>
        <NavBar aria-label={formatMessage({ id: 'primaryNavLinks' })}>{children}</NavBar>
      </HeaderContainer>

      <StyledLoadingIndicator
        as={LoadingIndicator}
        hasStoreSelection={isStoreOpenAndAvailable && !enableBottomServiceMode}
      />

      {isMobile && enableTopServiceMode && (
        <TopServiceModeContainer>
          <TopServiceMode />
        </TopServiceModeContainer>
      )}

      {!enableBottomServiceMode && !enableTopServiceMode && <DeliveryBannerContainer />}
      <OrderTrackingBannerContainerMaybe />
      <AppSubheader
        serviceMode={serviceMode!}
        userAddress={deliveryAddress?.addressLine1}
        cateringPickupDateTime={cateringPickupDateTime}
      />
    </View>
  );
};

import React from 'react';

import useMediaQuery from 'hooks/use-media-query';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { LoyaltyFab } from './loyalty-fab';
import { MainFab } from './main-fab';

export const FabContainer: React.FC = () => {
  const enableMainFabButton = useFlag(LaunchDarklyFlag.ENABLE_MAIN_FAB_BUTTON);
  const enableLoyaltyFloatingCartButton = useFlag(
    LaunchDarklyFlag.ENABLE_LOYALTY_FLOATING_CART_BUTTON
  );

  const isMobile = useMediaQuery('headerMobile');
  const shouldRenderMainButton = enableMainFabButton && isMobile;
  const shouldRenderLoyaltyButton = enableLoyaltyFloatingCartButton && isMobile;

  return (
    <>
      {shouldRenderMainButton ? <MainFab /> : null}
      {shouldRenderLoyaltyButton ? <LoyaltyFab /> : null}
    </>
  );
};

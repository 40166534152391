import { Loggable } from '@rbi-ctg/frontend';
import { RBIEnv } from 'utils/environment';

import { ILoggerMiddleware } from './middleware';

export enum LogLevel {
  trace = 10,
  debug = 20,
  info = 30,
  warn = 40,
  error = 50,
  fatal = 60,
}

export type TContext = Record<string, Loggable>;

export type TLogFunction = <O extends Loggable | Array<Loggable>>(message: O) => void;

export type TMethod = keyof typeof LogLevel;

type TLoggerMethods = Record<TMethod, TLogFunction>;

export interface ILogger<O extends TContext = {}> extends TLoggerMethods {
  child<P extends TContext>(
    initialContext: P,
    loggerOptions?: Omit<ICreateLoggerOptions, 'currentEnv'>
  ): ILogger<O & P>;
  getContext(): TContext;
  setContext(newCtx: TContext): void;
}

export interface ICreateLoggerOptions {
  middlewares?: ILoggerMiddleware[];
  currentEnv?: RBIEnv;
  handlers?: TLogHandler[];
}

/**
 * Handler for logging messages that includes the message, context, and log level.
 */
export type TLogHandler = (params: {
  message: Loggable;
  context: TContext;
  level: LogLevel;
  method: TMethod;
  env: RBIEnv;
}) => void;

import { AllowedEvent } from '@rbilabs/intl-mparticle-client';
import { noop } from 'lodash';

import { IBackendCartEntries, ICartEntry, IServerOrder, ServiceMode } from '@rbi-ctg/menu';
import { IUpdateUserAttributesEventInput } from 'generated/graphql-gateway';
import { ProviderType } from 'generated/rbi-graphql';
import { IStaticPageRoute } from 'remote/queries/static-page';
import { UserDetails } from 'state/auth/hooks/use-current-user';
import { StoreProxy } from 'state/store/hooks/types';
import { OTPAuthDeliveryMethod } from 'utils/otp';

import { CustomEventNames, EventTypes, ProductActionTypes, SignInPhases } from './constants';

export interface ILogEvent {
  (
    eventName: CustomEventNames | string,
    eventType: EventTypes,
    attributes?: object,
    customFlags?: object
  ): void;
}

export interface IGlobalAttributes {
  currentScreen: string;
  brand?: string;
  region?: string;
  env?: string;
  serviceMode?: string;
  appBuild?: string;
  browserType?: string;
  browserVersion?: string;
  isMobileWeb?: string;
  'Loyalty ID'?: string;
}

export interface IGenericEvent {
  name: CustomEventNames;
  type: EventTypes;
  globalAttributes?: IGlobalAttributes;
  attributes?: Record<string, string | number | undefined | boolean | unknown> | null;
  customFlags?: Record<string, string | boolean | number>;
}

export interface ItrackEvent {
  (event: AllowedEvent | IGenericEvent): void;
}

export interface ISignInEventOptions {
  phase: SignInPhases;
  success: boolean;
  message?: string;
  otpMethod?: OTPAuthDeliveryMethod;
  method?: SignInMethods;
  providerType?: ProviderType;
}

export interface ISignUpEventOptions {
  success: boolean;
  message?: string;
  otpMethod?: OTPAuthDeliveryMethod;
  additionalAttrs?: any;
}

export interface ITrackSignUpEvent {
  event: any;
  data: string;
}

export interface ISignUpTermToggleEventOptions {
  postToggleValue: boolean;
  signUpTermName: string;
}

export interface IAutoSignInEventOptions {
  phase: SignInPhases;
  success: boolean;
  message?: string;
}

export enum SignInMethods {
  OTP = 'OTP',
  SMS_OTP = 'SMS OTP',
  SOCIAL = 'Social',
  BIOMETRICS = 'Biometrics',
  AUTO_SIGN_UP = 'Auto Sign Up',
  GUEST = 'Guest',
}

export enum CdpProviderTypes {
  MParticle = 'mparticle',
  Bloomreach = 'bloomreach',
}

export const defaultCdpCtx: ICdpCtx = {
  init: noop,
  login: noop,
  logout: noop,
  deviceId: 'fakeid',
  sessionId: '',
  providerName: CdpProviderTypes.MParticle,
  signInEvent: noop,
  signOutEvent: noop,
  signUpEvent: noop,
  signUpTermToggleEvent: noop,
  autoSignInEvent: noop,
  updateUniversalAttributes: noop,
  updateStaticRoutes: noop,
  updateUserAttributes: noop,
  updateUserLocationPermissionStatus: noop,
  updateUserIdentities: noop,
  logPageView: noop,
  logCommercePageView: noop,
  addToCart: noop,
  updateItemInCart: noop,
  removeFromCart: noop,
  logOrderLatencyEvent: noop,
  logPurchase: noop,
  logCustomerInitiatedRefund: noop,
  logError: noop,
  logValidationError: noop,
  logEvent: noop,
  trackEvent: noop,
  logNavigationClick: noop,
  logAddPaymentMethodClick: noop,
  logCashVoucherMethodClick: noop,
  selectServiceMode: noop,
  logOfferActivatedEvent: noop,
  logCheckoutEvent: noop,
  logUpsellAddedEvent: noop,
  logUpsellRemovedEvent: noop,
  logNavBarClickEvent: noop,
  marketingTileClickEvent: noop,
};
export interface ICdpCtx {
  init: VoidFunction;
  login: any;
  logout: any;
  deviceId: string;
  sessionId: string;
  providerName: CdpProviderTypes;
  signInEvent: (options: ISignInEventOptions) => void;
  signOutEvent: (success: boolean, message?: string) => void;
  signUpEvent: (options: ISignUpEventOptions) => void;
  signUpTermToggleEvent: (options: ISignUpTermToggleEventOptions) => void;
  autoSignInEvent: (options: IAutoSignInEventOptions) => void;
  updateUniversalAttributes: (universalAttributes: Partial<ICdpUniversalAttributes>) => void;
  updateStaticRoutes: (newStaticRoutes: IStaticPageRoute[]) => void;
  updateUserAttributes: any;
  updateUserLocationPermissionStatus: VoidFunction;
  updateUserIdentities: (
    userIdentity?: {
      email?: string;
      customerid?: string;
      ccToken?: string;
    },
    additional?: {
      callback?: VoidFunction;
      tryAgain?: boolean;
    }
  ) => void;

  //pageView data
  logPageView: any;
  logCommercePageView: (
    menuData: { id: string; name: string; menuType: string },
    attrs?: {}
  ) => void;

  //eCommerce events
  addToCart(
    cartEntry: ICartEntry,
    serviceMode: ServiceMode,
    previousCartEntries: ICartEntry[],
    sendUpdateUserAttributesEvent: SendUpdateUserAttributesEventType,
    selectionAttrs?: IAddToCartSelectionAttributes
  ): void;

  updateItemInCart(
    cartEntry: ICartEntry,
    originalCartEntry: ICartEntry,
    serviceMode: ServiceMode,
    sendUpdateUserAttributesEvent: SendUpdateUserAttributesEventType
  ): void;

  removeFromCart(cartEntry: ICartEntry): void;

  logOrderLatencyEvent(
    order: IServerOrder | undefined,
    eventName: 'commit' | 'price',
    duration: number,
    sendUpdateUserAttributesEvent: SendUpdateUserAttributesEventType
  ): void;

  logPurchase(
    cartEntries: ICartEntry[],
    store: StoreProxy,
    serviceMode: ServiceMode,
    serverOrder: IServerOrder,
    sendUpdateUserAttributesEvent: SendUpdateUserAttributesEventType,
    attrs?: {
      currencyCode: string;
      fireOrderInMinutes: number;
    }
  ): void;

  logCustomerInitiatedRefund(
    event: CustomEventNames,
    items?: IBackendCartEntries[],
    amount?: number,
    reason?: string
  ): void;

  //error tracking
  logError: (
    error: { name: string; message?: string; stack?: string },
    attrs?: { [key: string]: string | number | boolean }
  ) => void;

  //validation errors
  logValidationError: (
    error: { name: string; description?: string },
    attrs?: { [key: string]: string | number | boolean }
  ) => void;

  //custom events
  logEvent: ILogEvent;
  trackEvent: ItrackEvent;
  logNavigationClick: (
    eventName: CustomEventNames,
    attributes?: object,
    customFlags?: object
  ) => void;
  logAddPaymentMethodClick: VoidFunction;
  logCashVoucherMethodClick: VoidFunction;
  selectServiceMode: (mode: ServiceMode) => void;
  logOfferActivatedEvent: (sanityId: string, offerName: string, tokenId?: string | null) => void;

  logCheckoutEvent(
    serviceMode: ServiceMode,
    cartEntries: ICartEntry[],
    sendUpdateUserAttributesEvent: SendUpdateUserAttributesEventType
  ): void;

  logUpsellAddedEvent: (item: ICartEntry, itemPosition?: number) => void;
  logUpsellRemovedEvent: (item: ICartEntry) => void;
  logNavBarClickEvent: (text: string, componentKey?: string) => void;
  marketingTileClickEvent: (title: string, position: number, cardID: string) => void;
}

export interface ICdpAttributes {
  $FirstName?: string;
  $LastName?: string;
  $Zip?: string;
  $Age?: number | null;
  $Gender?: string;
  $City?: string;
  $State?: string;
  $Mobile?: string | null;
  'Battery Level'?: number;
  dob?: Date | null;
  'Date of Birth'?: string | null;
  'Email Opt In'?: string;
  favoriteStores?: string;
  favoriteOffers?: string;
  'Join Date'?: string;
  language?: string;
  Locale?: string;
  'Legacy User'?: string;
  'Location Services'?: string;
  marketingEmail?: string;
  marketingPush?: string;
  mediaServicesPreferences?: string;
  orderStatus?: string;
  'RBI Cognito ID'?: string;
  rewardsEmail?: string;
  rewardsPush?: string;
  Tier?: string | null;
  Timezone?: string;
  'Type Preference'?: string;
  'Snack Preference'?: string;
  'Size Preference'?: string;
  'Time Preference'?: string;
  'UTM Source'?: string;
  'UTM Medium'?: string;
  'UTM Campaign'?: string;
  'UTM Term'?: string;
  'UTM Content'?: string;
  'IOS Location Permissions'?: string;
  'Android Location Permissions'?: string;
}

export interface ExtendedCdpAttributes extends ICdpAttributes {
  'Is Guest User'?: boolean;
  paybackUserId?: string;
  email_subscribe?: string;
  push_subscribe?: string;
  sms_subscribe?: string;
  serviceMode?: string;
  'Pickup Mode'?: string;
  'Source Page'?: string;
  browserType?: string;
  browserVersion?: string;
  isMobileWeb?: string;
  isSmallScreen?: string;
  layer?: string;
  enableFlavorFlow?: string;
  CustomerID?: string;
  'Promotional Emails'?: string;
  currentScreen?: string;
  appBuild?: string;
  loyalty?: string;
  currentBuild?: string;
}

export interface ICdpCart {
  /**
   * Adds a cart product to the user cart
   * @method add
   * @param {Object} product the product
   * @param {Boolean} [logEvent] a boolean to log adding of the cart object. If blank, no logging occurs.
   */
  add: (product: ICdpProduct, logEvent: boolean) => void;
  /**
   * Clears all products from the user cart
   * @method clear
   */
  clear: VoidFunction;
  /**
   * Removes a cart product from the current user cart
   * @method remove
   * @param {Object} product the product
   * @param {Boolean} [logEvent] a boolean to log adding of the cart object. If blank, no logging occurs.
   */
  remove: (product: ICdpProduct, logEvent: boolean) => void;
  /**
   * Returns all cart products
   * @method getCartProducts
   * @return {Array} array of cart products
   */
  getCartProducts: () => ICdpProduct[];
}

interface ILoggedOutCdpUserIdentity {}

export interface ICdpUserIdentity {
  email?: string;
  customerid: string;
  other3?: string;
}

export interface ICdpUser {
  /**
   * Get user identities for current user
   * @method getUserIdentities
   * @return {Object} an object with userIdentities as its key
   */
  getUserIdentities: () => {
    userIdentities: ICdpUserIdentity;
  };
  // todo finish this interface
  getAllUserAttributes: () => ICdpAttributes;
  setUserAttributes: (attributes: ICdpAttributes) => void;
  removeUserAttribute: (attribute: string) => void;
  isLoggedIn: () => boolean;
  getMPID?: () => string;
  /**
   * Returns the cart object for the current user
   * @method getCart
   * @return a cart object
   */
  getCart: () => ICdpCart;
}

export enum HTTPCode {
  SUCCESS = 200,
  NO_HTTP_COVERAGE = -1,
  ACTIVE_IDENTITY_REQUEST = -2,
  ACTIVE_SESSION = -3,
  VALIDATION_ISSUE = -4,
  NATIVE_IDENTITY_REQUEST = -5,
  LOGGING_DISABLED_OR_MISSING_API_KEY = -6,
  TOO_MANY_REQUESTS = 42,
}

export interface ICdpIdentityModifyCallback {
  (args: { httpCode: HTTPCode; body: string | null }): void;
}

export interface ICdpCallback {
  (args: {
    httpCode: HTTPCode;
    body: string;
    getUser: () => ICdpUser;
    getPreviousUser: () => ICdpUser;
  }): void;
}

export interface ICdpIdentityApiData {
  userIdentities: ICdpUserIdentity | ILoggedOutCdpUserIdentity;
}

interface ICdpIdentityApiCallback {
  (identityApiData: ICdpIdentityApiData, callback: ICdpCallback): void;
}

interface ICdpIdentityApiModifyCallback {
  (identityApiData: ICdpIdentityApiData, callback: ICdpIdentityModifyCallback): void;
}

export type SendUpdateUserAttributesEventType = (options: {
  variables: { input: IUpdateUserAttributesEventInput };
}) => void;

export interface ICdpProduct {
  Name: string;
  Sku: string;
  Price: number;
  Quantity: number;
  Brand?: string;
  Variant?: string;
  Category?: string;
  Position?: number;
  CouponCode?: string;
  TotalAmount?: number;
  Attributes?: { [key: string]: string | number | boolean };
  SubProducts?: ICdpProduct[];
}

export interface ICdp {
  eCommerce?: {
    /**
     * Creates a product
     * @for eCommerce
     * @method createProduct
     * @param {String} name product name
     * @param {String} sku product sku
     * @param {Number} price product price
     * @param {Number} [quantity] product quantity. If blank, defaults to 1.
     * @param {String} [variant] product variant
     * @param {String} [category] product category
     * @param {String} [brand] product brand
     * @param {Number} [position] product position
     * @param {String} [coupon] product coupon
     * @param {Object} [attributes] product attributes
     */
    createProduct?: (
      name: string,
      sku: string,
      price: number,
      quantity?: number,
      variant?: string,
      category?: string,
      brand?: string,
      position?: number,
      coupon?: string,
      attributes?: { [key: string]: string | number | boolean }
    ) => null | ICdpProduct;
    logProductAction?: (
      type: number,
      product: ICdpProduct[] | ICdpProduct,
      attrs?: object | null,
      flags?: object | null,
      transactionalAttrs?: object
    ) => void;
    logPurchase: any;
  };
  sessionManager?: {
    initialize: VoidFunction;
    getSession: () => string;
  };
  Identity?: {
    /**
     * Initiate a login request to the cdp server
     * @method login
     * @param {Object} identityApiData The identityApiData object based on [here](https://github.com/mParticle/mparticle-sdk-javascript/blob/master-v2/README.md#1-customize-the-sdk)
     * @param {Function} [callback] A callback function that is called when the login request completes
     */
    login?: ICdpIdentityApiCallback;
    logout?: ICdpIdentityApiCallback;
    getCurrentUser?: () => ICdpUser;
    modify?: ICdpIdentityApiModifyCallback;
    identify?: ICdpIdentityApiCallback;
  };
  /**
   * Used to log custom errors
   *
   * @method logError
   * @param {String or Object} error The name of the error (string), or an object formed as follows {name: 'exampleName', message: 'exampleMessage', stack: 'exampleStack'}
   * @param {Object} [attrs] Custom attrs to be passed along with the error event; values must be string, number, or boolean
   */
  logError?: (
    error: string | { name: string; message?: string; stack?: string },
    attrs?: { [key: string]: string | number | boolean }
  ) => void;
  // logError: (...args: any) => void;
  logPageView?: (...args: any) => void;
  logCustomPageView?: (path: string, attrs?: any) => void;
  logCommercePageView?: (
    menuData: { id: string; name: string; menuType: string },
    attrs?: any
  ) => void;
  logEvent?: (
    name: string,
    eventTypes: EventTypes,
    attributes?: object,
    customFlags?: object
  ) => void;
  EventType: {
    Navigation: EventTypes.Navigation;
    Location: EventTypes.Location;
    Search: EventTypes.Search;
    Transaction: EventTypes.Transaction;
    UserContent: EventTypes.UserContent;
    UserPreference: EventTypes.UserPreference;
    Social: EventTypes.Social;
    Other: EventTypes.Other;
  };
  getDeviceId?: () => string;
  ProductActionType?: {
    Unknown: number;
    AddToCart: number;
    RemoveFromCart: number;
    Checkout: number;
    CheckoutOption: number;
    Click: number;
    ViewDetail: number;
    Purchase: number;
    Refund: number;
    AddToWishlist: number;
    RemoveFromWishlist: number;
  };
  setOptOut?: (state: boolean) => void;
  _getActiveForwarders?: () => any[];
  //Bloomreach interface
  track?: (
    eventName: string,
    eventProperties: any,
    successCallback?: Function,
    errorCallback?: Function
  ) => {};
  update?: (customerProperties: object) => {};
  identify?: (
    customerIds?: any,
    customerProperties?: object,
    successCallback?: Function,
    errorCallback?: Function,
    immediate?: boolean
  ) => VoidFunction;
  anonymize?: () => VoidFunction;
  start?: () => VoidFunction;
}

export interface ICdpPurchaseEventAttributes {
  'Pickup Mode': string;
  'Service Mode': string;
  customer_event_alias: string;
  'CC Token': string | null;
  'Coupon ID': string;
  'Coupon Applied': string;
  Currency: string;
  'Tax Amount': number;
  'Total Amount': number;
  'Value Threshold 20 Met'?: boolean;
  'Value Threshold 15 Met'?: boolean;
  'Value Threshold 10 Met'?: boolean;
  'Value Threshold 5 Met'?: boolean;
  'Timed Fire Minutes': number;
  'Transaction Order Number ID': string;
  'Transaction POS': string;
  'Transaction RBI Cloud Order ID': string;
  'Restaurant ID': string | null;
  'Restaurant Name': string | null;
  'Restaurant Number': string | null;
  'Restaurant Address': string | null;
  'Restaurant City': string | null;
  'Restaurant State/Province Name': string | null;
  'Restaurant Postal Code': string | null;
  'Restaurant Country': string | null;
  'Restaurant Latitude': number | null;
  'Restaurant Longitude': number | null;
  'Restaurant Status': string | null;
  'Restaurant Drink Station Type': string | null;
  'Restaurant Drive Thru Lane Type': string | null;
  'Restaurant Franchise Group Id': number | null;
  'Restaurant Franchise Group Name': string | null;
  'Restaurant Front Counter Closed': boolean | null;
  'Restaurant Has Breakfast': boolean | null;
  'Restaurant Has Burgers For Breakfast': boolean | null;
  'Restaurant Has Curbside': boolean | null;
  'Restaurant Has Front Counter Closed': boolean | null;
  'Restaurant Has Catering': boolean | null;
  'Restaurant Has Dine In': boolean | null;
  'Restaurant Has Drive Thru': boolean | null;
  'Restaurant Has Mobile Ordering': boolean | null;
  'Restaurant Has Parking': boolean | null;
  'Restaurant Has Playground': boolean | null;
  'Restaurant Has Take Out': boolean | null;
  'Restaurant Has Wifi': boolean | null;
  'Restaurant Number Drive Thru Windows': number;
  'Restaurant Parking Type': string | null;
  'Restaurant Playground Type': string | null;
  'Restaurant POS': string | null;
  'Restaurant POS Version': string | null;
  'Card Type': string;
  'Payment Type': string;
  'Has Upsell': boolean;
  'Upsell Total': number;
  'Recommender Provider': string;
  Chef: string | null;
  'Source Page': string;
  quotedFeeAmount?: number;
  hasSavedDeliveryAddress?: boolean;
  'Address Type'?: string | null;
  'Cart Data': string;
  Rewards: string | null;
  'Currency Code': string;
  'Upsell Simplified Enabled': boolean;
  hasRecentAddress?: boolean;
  hasSelectedRecentAddress?: boolean;
  'Is Guest Order'?: boolean;
  'Guest ID'?: string;
}

export interface ICdpUniversalAttributes {
  'Service Mode'?: string;
  'Pickup Mode'?: string;
  'Source Page'?: string;
  locale?: string;
  browserType?: string | null;
  browserVersion?: string | null;
  isMobileWeb?: string | null;
  isSmallScreen?: boolean;
  currentBuild?: string;
  enableFlavorFlow?: boolean;
  layer?: 'FE';
  'Loyalty ID'?: string;
  'Restaurant Address'?: string;
  'Restaurant ID'?: string;
  'Restaurant Name'?: string;
  'Restaurant Number'?: string;
  'Sanity Restaurant ID'?: string;
}

export interface ICdpSublevelItem {
  id: string;
  quantity: number;
}

export abstract class ICdpIdentityAdapter {
  static modify: ICdpIdentityApiModifyCallback;
}

export interface ICdpAdapter {
  createProduct?: (
    name: string,
    sku: string,
    price: number,
    quantity: number
  ) => ICdpProduct | null;
  logEvent: (
    name: string,
    eventTypes: EventTypes,
    attributes?: object,
    customFlags?: object
  ) => void;
  logProductAction?: (
    type: ProductActionTypes,
    products: ICdpProduct[],
    attrs?: object | null,
    flags?: object | null,
    transactionalAttrs?: object
  ) => void;

  getDeviceId?: () => string | void;

  getSession?: () => Promise<{ sessionId: string }>;
  setOptOut?: (optOut: boolean) => void;

  Identity?: ICdpIdentityAdapter;
}

// Track the modification of item, combo slot and picker aspect.
// These values are initially false and is set to true if the user
// changes these values away from the initial state.
export interface IAddToCartSelectionAttributes {
  pickerAspectSelection: boolean;
  comboSlotSelection: boolean;
  itemModified: boolean;
}

export type UpdatedUserAttributes = Partial<UserDetails['details']> & {
  'Legacy User'?: string;
  'App Build'?: string;
  'Browser Type'?: string;
  'Browser Version'?: string;
  'Mobile Web'?: string;
  Locale?: string;
  'Pickup Mode'?: string;
  'Service Mode'?: string;
  'Loyalty ID'?: string;
  'Restaurant Address'?: string;
  'Restaurant ID'?: string;
  'Restaurant Name'?: string;
  'Restaurant Number'?: string;
  'Sanity Restaurant ID'?: string;
};

/* eslint-disable no-console */
import { StatusType, dataDogLogger } from 'utils/datadog';
import { RBIEnv } from 'utils/environment';

import { LogLevel, TLogHandler } from './types';

/**
 * Handler that sends logs to Datadog.
 *
 * Please note there are multiple factors that determine whether logs will be
 * forwarded to Datadog or not:
 * - The configured sampling rate
 * - The current environment (development vs. production)
 * - The configured log level
 */
export const datadogHandler: TLogHandler = ({ context, level, env }) => {
  if (level >= LogLevel.error && env !== RBIEnv.TEST) {
    dataDogLogger({
      message: (context.error as Error) || context.message,
      context,
      status: StatusType.error,
    });
  }
};

/**
 * We keep a copy of the original console methods because the DD-SDK overrides them.
 */
export const originalConsole = {
  debug: console.debug.bind(console),
  error: console.error.bind(console),
  fatal: console.error.bind(console),
  info: console.info.bind(console),
  trace: console.trace.bind(console),
  warn: console.warn.bind(console),
};

/**
 * Handler that prints out logs to the console.
 */
export const consoleHandler: TLogHandler = ({ context, env, method }) => {
  if (env === RBIEnv.DEV) {
    originalConsole[method](context.message, context);
  }
};

import {
  TouchEventHandler,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { debounce, isEqual } from 'lodash';

import { useSendUpdateUserAttributesEventMutation } from 'generated/graphql-gateway';
import { IRestaurantNode } from 'generated/rbi-graphql';
import useEffectOnce from 'hooks/use-effect-once';
import useMap from 'hooks/use-map';
import { usePrevious } from 'hooks/use-previous';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { useGeolocation } from 'state/geolocation';
import { OrderLocatorTab } from 'state/launchdarkly/variations';
import { useLocationContext } from 'state/location';
import { useOrderContext } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { isValidPosition } from 'utils/geolocation';
import { routes } from 'utils/routing';

import storeReducer, {
  StoreAction,
  initialState,
  searchNearbyNotGranted,
  setActiveStoreId as setActiveStoreIdAction,
} from '../ducks/stores';
import { LocatorTabIds, StoreLocatorViews } from '../types';

import useMarkers from './use-markers';
import useSearchRestaurants from './use-search-restaurants';
import useStoreLocatorTabs from './use-store-locator-tabs';
import useStoreLocatorView from './use-store-locator-view';

const setupEvtListeners = (onDragEnd: TouchEventHandler) => ({
  dragend: debounce(onDragEnd, 200),
});

export interface IUseStoreLocator {
  ErrorDialog: React.FunctionComponent;
  Dialog: React.FunctionComponent;
  activeStoreId?: string;
  hasPanned: boolean;
  map: JSX.Element;
  onClickSearch(): void;
  setIsStoreInfoModalOpen(value: boolean): void;
  redirectUrl: string;
  searchingNearby: boolean;
  searchingRecent: boolean;
  searchingFavs: boolean;
  setActiveStoreId: React.Dispatch<any>;
  nearbyStoreCardRefs?: {
    [key: string]: React.MutableRefObject<HTMLDivElement | null>;
  };
  storesNearby?: IRestaurantNode[];
  storesFavs?: IRestaurantNode[];
  storesRecent?: IRestaurantNode[];
  zoom: number;
  currentTab: OrderLocatorTab;
  handleTabChange(newValue: number): void;
  storeLocatorDispatch: StoreAction;
  currentTabHasError: boolean;
  locatorTabs: OrderLocatorTab[];
  currentTabIndex: number;
  locatorTabIds: LocatorTabIds;
  currentView: StoreLocatorViews;
  currentViewIsList: boolean;
  currentViewIsMap: boolean;
  updateStoreLocatorView: (newView: StoreLocatorViews) => void;
}

interface IUseStoreLocatorProps {
  // Loads position and nearest location on mount, default true
  loadPositionOnMount: boolean;
  // Indicates hook use on store locator page, default true
  onStoreLocator: boolean;
}

interface ICurrentcoords {
  lat?: number;
  lng?: number;
}

// TODO: Consider passing context values in, ie: state/payment/usePayment
// can make it easier and more straightforward to test
const defaultArgs = { loadPositionOnMount: true, onStoreLocator: true };
export default function useStoreLocator({
  loadPositionOnMount,
  onStoreLocator,
}: IUseStoreLocatorProps = defaultArgs): IUseStoreLocator {
  const cdp = useCdpContext();
  const { restaurantLogAttributesRef } = useStoreContext();
  const { serviceMode } = useServiceModeContext();

  const { currentView, currentViewIsList, currentViewIsMap, updateStoreLocatorView } =
    useStoreLocatorView();

  const [
    {
      errorFavs,
      errorNearby,
      errorRecent,
      storesNearby = [],
      searchingNearby,
      storesFavs = [],
      searchingFavs,
      storesRecent = [],
      searchingRecent,
      activeStoreId,
    },
    storeLocatorDispatch,
  ] = useReducer(storeReducer, initialState);

  const {
    currentTab,
    currentTabHasError,
    currentTabIndex,
    handleTabChange,
    locatorTabIds,
    locatorTabs,
  } = useStoreLocatorTabs({ errorFavs, errorNearby, errorRecent });

  const { loadCurrentPosition, activeCoordinates, isPermissionDenied, isPermissionPrompt } =
    useGeolocation();
  const [sendUpdateUserAttributesEvent] = useSendUpdateUserAttributesEventMutation();
  const { clearServerOrder } = useOrderContext();
  const [hasPanned, setHasPanned] = useState(false);
  const [isStoreInfoModalOpen, setIsStoreInfoModalOpen] = useState(false);
  const { storeLocatorCallbackUrl } = useLocationContext();

  const eventListeners = useMemo(
    () =>
      setupEvtListeners(() => {
        setHasPanned(true);
      }),
    []
  );

  useEffectOnce(() => {
    clearServerOrder();
  });

  const { center, createMarker, domRef, map, markers, panTo, zoom } = useMap(
    activeCoordinates
      ? {
          eventListeners,
          position: activeCoordinates,
        }
      : { eventListeners }
  );

  const {
    ErrorDialog,
    Dialog,
    searchNearbyRestaurants,
    searchFavRestaurants,
    searchRecentRestaurants,
  } = useSearchRestaurants({
    storeLocatorDispatch,
  });

  /**
   * nearbyStoreCardRefs is used to store the DOM ref for store cards
   *  that also are shown on the map as store icons. When a user
   *  clicks on the store icon on the map the storesNearby associated
   *  nearbyStoreCardRefs ref is scrolled into view
   */
  const nearbyStoreCardRefs = useMemo(
    () =>
      storesNearby?.reduce((obj, store) => {
        if (store._id) {
          obj[store._id] = createRef();
        }

        return obj;
      }, {}),
    [storesNearby]
  );

  const setActiveStoreId = (storeId: string) => {
    storeLocatorDispatch(setActiveStoreIdAction(storeId));
  };

  const storesNearbyPrevValue = usePrevious(storesNearby);
  const redirectUrl = storeLocatorCallbackUrl || routes.menu;

  useMarkers({
    createMarker,
    map,
    markers,
    storesFavs,
    panTo,
    storesNearby,
    activeStoreId,
    onClickMarker: (store: IRestaurantNode) => {
      if (store._id === activeStoreId) {
        return;
      }
      cdp.trackEvent({
        name: CustomEventNames.BUTTON_CLICK,
        type: EventTypes.Other,
        attributes: {
          Name: 'Store card event',
          StoreId: store._id,
          ...restaurantLogAttributesRef.current,
        },
      });
      cdp.updateUserAttributes(
        {
          'Sanity Restaurant ID': store._id,
        },
        {},
        sendUpdateUserAttributesEvent
      );

      const { latitude: lat, longitude: lng } = store;

      if (lat && lng) {
        return searchNearbyRestaurants({ location: { lat, lng } });
      }
    },
  });

  /*
   * Search restaurants in list view:
   * - only perform search for nearby when we have coords
   */
  const searchRestaurantsForListView = useCallback(
    (coords: ICurrentcoords | null) => {
      // If the user has not granted geolocalisation permission and has not
      // make a manual search (coords === null), make the nearby store an empty array
      if (!coords && (isPermissionDenied || isPermissionPrompt)) {
        return storeLocatorDispatch(searchNearbyNotGranted());
      }
      if (isValidPosition(coords)) {
        return searchNearbyRestaurants({
          location: coords,
        });
      }
    },
    [isPermissionDenied, isPermissionPrompt, searchNearbyRestaurants]
  );

  /**
   * Perform map view store searches when:
   * - map is mounted, domRef
   * - user coordinates being updated are valid
   * - user navigates from list view to map view
   * - store info modal is closed
   *
   * NOTE: If not on store locator page e.g onStoreLocator = false, return
   */
  useEffect(() => {
    if (currentViewIsList || isStoreInfoModalOpen || !onStoreLocator) {
      return;
    }
    if (domRef.current && isValidPosition(activeCoordinates)) {
      searchNearbyRestaurants({
        location: activeCoordinates,
      });
    }
  }, [
    activeCoordinates,
    isStoreInfoModalOpen,
    domRef,
    zoom,
    onStoreLocator,
    searchNearbyRestaurants,
    currentViewIsList,
  ]);

  useEffect(() => {
    searchFavRestaurants(activeCoordinates);
  }, [activeCoordinates, searchFavRestaurants]);

  useEffect(() => {
    searchRecentRestaurants(activeCoordinates);
  }, [activeCoordinates, searchRecentRestaurants]);

  /*
    Perform list view store searches when:
    - activeCoordinates change
    - currentViewIsList changes
    - store info modal is closed
    - Not on the store locator page e.g. onStoreLocator = false;
    - The user changes service mode
  */
  useEffect(() => {
    if (!onStoreLocator || (currentViewIsList && !isStoreInfoModalOpen)) {
      searchRestaurantsForListView(activeCoordinates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCoordinates, currentViewIsList, isStoreInfoModalOpen, serviceMode]);

  /*
   * Try getting user's current position on mount
   */
  useEffectOnce(() => {
    if (loadPositionOnMount) {
      loadCurrentPosition();
    }
  });

  /**
   * Logs "Restaurant Found" cdp event
   */
  useEffect(() => {
    if (isEqual(storesNearby, storesNearbyPrevValue)) {
      return;
    }
    const numberOfRestaurantsFound = storesNearby.length;
    const numberOfAvailableRestaurants = storesNearby.filter(r => r.hasMobileOrdering).length;
    const numberOfUnavailableRestaurants = numberOfRestaurantsFound - numberOfAvailableRestaurants;

    cdp.trackEvent({
      name: CustomEventNames.RESTAURANTS_FOUND,
      type: EventTypes.Other,
      attributes: {
        Restaurants: numberOfRestaurantsFound,
        'Available Restaurants': numberOfAvailableRestaurants,
        'Unavailable Restaurants': numberOfUnavailableRestaurants,
      },
    });
  }, [cdp, storesNearby, storesNearbyPrevValue]);

  const onClickSearch = useCallback(() => {
    searchNearbyRestaurants({
      location: center,
    });

    setHasPanned(false);
  }, [center, searchNearbyRestaurants]);

  return {
    ErrorDialog,
    Dialog,
    activeStoreId,
    hasPanned,
    map,
    onClickSearch,
    redirectUrl,
    searchingNearby: searchingNearby || !storesNearby,
    searchingRecent: searchingRecent || !storesRecent,
    searchingFavs: searchingFavs || !storesFavs,
    setActiveStoreId,
    nearbyStoreCardRefs,
    storesNearby,
    storesFavs,
    storesRecent,
    zoom,
    currentTab,
    handleTabChange,
    setIsStoreInfoModalOpen,
    storeLocatorDispatch,
    currentTabHasError,
    locatorTabs,
    currentTabIndex,
    locatorTabIds,
    currentView,
    currentViewIsList,
    currentViewIsMap,
    updateStoreLocatorView,
  };
}

/* eslint-disable no-console */
import { RBIEnv, env, isLocalDev } from 'utils/environment';

// simple logger for availability
export const availabilityLogger = <
  I extends { _type?: string; name?: { locale: string } | string },
>(
  item: I | undefined | null,
  reason: string
) => {
  if (env() === RBIEnv.PROD || isLocalDev) {
    return;
  }
  const name =
    Boolean(item?.name) && typeof item?.name === 'object'
      ? item.name?.locale
      : item?.name || 'Unknown name';
  console.groupCollapsed(
    `Unavailable ${item?._type ? item._type : 'item of unknown type'}: ${name}`
  );
  console.info({ item, message: reason });
  console.groupEnd();
};

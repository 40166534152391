import React, { MouseEvent, useCallback, useState } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { useIntl } from 'react-intl';
import { useMatch, useNavigate } from 'react-router-dom';

import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import OrderCancelledModal from 'components/order-cancelled-modal';
import { useSendUpdateUserAttributesEventMutation } from 'generated/graphql-gateway';
import { DeliveryStatus, IUserOrderFragment, RbiOrderStatus } from 'generated/rbi-graphql';
import useDialogModal from 'hooks/use-dialog-modal';
import { useUserOrders } from 'hooks/use-user-orders';
import { STATUS_MESSAGES } from 'pages/order-confirmation/delivery/delivery-progress';
import { getCustomerFacingDeliveryStatuses } from 'pages/order-confirmation/delivery/get-customer-facing-delivery-statuses';
import { useAuthContext } from 'state/auth';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { ServiceMode, useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

import { LOYALTY_REDEEM_TEXT_KEY } from '../constants';
import theme from '../theme';
import {
  BottomServiceVariants,
  IBottomServiceModeDetail,
  IOrderInProgress,
  IOrderInProgressResult,
} from '../types';
import { deliveryCompleteTimeout, postOrderTimeout } from '../utils';

import { useCartButtonText } from './use-cart-button-text';
import { useRewardQuantity } from './use-reward-quantity';

const DELIVERY_COMPLETED = [
  DeliveryStatus.ORDER_DROPPED_OFF,
  DeliveryStatus.ORDER_ABANDONED,
  DeliveryStatus.ORDER_CANCELLED,
];

const ORDER_PROGRESS_POLLING_DURATION_IN_MS = 10000;
const DELIVERY_COMPLETED_TIMEOUT_IN_MINUTES = 10;
export const PICKUP_UPDATED_TIMEOUT_IN_MINUTES = 10;
export const PRE_CURBSIDE_CONFIRM_ARRIVAL_TIMEOUT_IN_MINUTES = 60;

export const getDeliveryHeading = ({
  deliveryStatus,
  rbiOrderStatus,
}: {
  deliveryStatus: DeliveryStatus;
  rbiOrderStatus: RbiOrderStatus;
}) => {
  const { isBeingPrepared, isDriverEnroute, isDelivered } = getCustomerFacingDeliveryStatuses(
    deliveryStatus,
    rbiOrderStatus
  );
  if (isDelivered) {
    return 'deliveryDelivered';
  }
  if (isDriverEnroute) {
    return 'deliveryDriverEnroute';
  }

  if (isBeingPrepared) {
    return 'deliveryOrderBeingPrepared';
  }

  return 'orderPlaced';
};

export const isPollingForDelivery = (
  recentOrder: IUserOrderFragment | null | undefined,
  isDeliveryOrderCompleted: boolean
) => {
  if (
    recentOrder?.delivery &&
    !isDeliveryOrderCompleted &&
    !DELIVERY_COMPLETED.includes(recentOrder.delivery.status)
  ) {
    return true;
  }
  return false;
};

// Timeout time of 10 minutes post order placed:
// - ServiceMode.TAKEOUT
// - ServiceMode.DRIVE_THRU
// - ServiceMode.EAT_IN
// - ServiceMode.CURBSIDE
// - ServiceMode.DELIVERY
// Timeout time of 60 minutes until order placed, pre-confirm arrival:
// - ServiceMode.CURBSIDE
export const orderInProgress = ({
  recentOrder,
  fireOrderAhead,
  curbsidePickupOrderId,
  curbsidePickupOrderTimePlaced,
  isDeliveryOrderCompleted,
}: IOrderInProgress): IOrderInProgressResult | null => {
  if (
    recentOrder &&
    [ServiceMode.CATERING_PICKUP, ServiceMode.CATERING_DELIVERY].includes(
      recentOrder?.cart.serviceMode
    )
  ) {
    return null;
  }

  const isPickupTimedOut = () => {
    // Add the fire order delay to the total timeout
    const fireOrderInSeconds = recentOrder?.fireOrderIn
      ? recentOrder?.fireOrderIn + fireOrderAhead
      : 0;
    const fireOrderInMinutes = fireOrderInSeconds / 60;
    const timeOutWithDelay = PICKUP_UPDATED_TIMEOUT_IN_MINUTES + fireOrderInMinutes;

    return (
      recentOrder &&
      !recentOrder?.delivery &&
      postOrderTimeout(recentOrder?.updatedAt, timeOutWithDelay)
    );
  };

  const isPreConfirmCurbsideTimedOut = () =>
    curbsidePickupOrderId &&
    postOrderTimeout(
      curbsidePickupOrderTimePlaced,
      PRE_CURBSIDE_CONFIRM_ARRIVAL_TIMEOUT_IN_MINUTES
    );

  // ServiceMode.DELIVERY - ordered
  if (recentOrder?.delivery && !isDeliveryOrderCompleted) {
    const deliveryStatus = recentOrder.delivery.status;
    const { status: rbiOrderStatus } = recentOrder;
    const headingId = getDeliveryHeading({ deliveryStatus, rbiOrderStatus });
    const detailsId = STATUS_MESSAGES[deliveryStatus];
    const buttonContentTextId =
      deliveryStatus === DeliveryStatus.ORDER_DROPPED_OFF ? 'details' : 'track';
    return {
      headingId,
      detailsId,
      buttonContentTextId,
      buttonContentIcon: null,
      icon: <Icon icon="location" color="white" width="24px" height="24px" aria-hidden />,
      variant: BottomServiceVariants.IN_PROGRESS,
    };
  }
  // PICKUP - ordered
  if (recentOrder && isPickupTimedOut()) {
    return {
      headingId: 'orderPlaced',
      detailsId: 'yourOrderIsBeingPrepared',
      icon: <Icon icon="restaurant" color="white" width="24px" height="24px" aria-hidden />,
      buttonContentTextId: 'details',
      buttonContentIcon: null,
      variant: BottomServiceVariants.IN_PROGRESS,
    };
  }
  // ServiceMode.CURBSIDE - Before order is commited on confirming arrival , selecting "I'm here!"
  if (isPreConfirmCurbsideTimedOut()) {
    return {
      headingId: 'serviceModeDetailsCurbsideHeader',
      detailsId: 'headToRestaurant',
      icon: <Icon icon="restaurant" color="white" width="24px" height="24px" aria-hidden />,
      buttonContentTextId: 'confirmArrival',
      buttonContentIcon: null,
      variant: BottomServiceVariants.IN_PROGRESS,
    };
  }

  return null;
};

export const useBottomServiceModeDetails = (): IBottomServiceModeDetail => {
  const {
    serviceMode,
    deliveryAddress,
    isCartEmpty,
    curbsidePickupOrderId,
    curbsidePickupOrderTimePlaced,
  } = useOrderContext();
  const { store, noStoreSelected } = useStoreContext();
  const rewardQuantity = useRewardQuantity();
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const fireOrderAhead = useFlag(LaunchDarklyFlag.FIRE_ORDER_AHEAD);
  const { formatMessage } = useIntl();
  const { trackEvent, updateUserAttributes } = useCdpContext();
  const [sendUpdateUserAttributesEvent] = useSendUpdateUserAttributesEventMutation();
  const { isAuthenticated } = useAuthContext();
  const isAuth = isAuthenticated();
  const [hasSeenCancelModal, setHasSeenCancelModal] = useState(false);
  const [CancellationModal, openCancellationModal] = useDialogModal({
    Component: OrderCancelledModal,
    onDismiss: () => setHasSeenCancelModal(true),
  });
  const navigate = useNavigate();
  const [ConfirmationModal, openConfirmationModal] = useDialogModal({
    showCancel: true,
    onConfirm: () => navigate(routes.serviceMode),
    modalAppearanceEventMessage: 'Service Mode/Address Confirmation',
  });

  const { cartButtonText } = useCartButtonText();

  const isOnMenuPage = useMatch({
    path: routes.menu,
    end: false,
  });

  const { orders, called, startPolling, stopPolling, refetch } = useUserOrders({
    variables: {
      limit: 1,
      orderStatuses: [
        RbiOrderStatus.INSERT_SUCCESSFUL,
        RbiOrderStatus.UPDATE_SUCCESSFUL,
        RbiOrderStatus.UPDATE_ERROR, // not a terminal status, the order could continue
        RbiOrderStatus.REFUND_SUCCESSFUL,
      ],
    },
  });
  const { inRestaurantRedemptionEnabled, inRestaurantRedemptionCart } =
    useInRestaurantRedemptionContext();

  const handleCartClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      trackEvent({
        name: CustomEventNames.BOTTOM_SERVICE_CART,
        type: EventTypes.Other,
        attributes: {
          'Service Mode': serviceMode,
        },
      });
      updateUserAttributes({ 'Service Mode': serviceMode }, {}, sendUpdateUserAttributesEvent);
      if (store._id) {
        navigate(routes.cart);
      } else {
        navigate(routes.serviceMode);
      }
    },
    [trackEvent, navigate, serviceMode, store._id]
  );
  const handleServiceModeChange = useCallback(() => {
    trackEvent({
      name: CustomEventNames.BOTTOM_SERVICE_MODE,
      type: EventTypes.Other,
      attributes: {
        'Service Mode': serviceMode,
      },
    });
    updateUserAttributes({ 'Service Mode': serviceMode }, {}, sendUpdateUserAttributesEvent);
    navigate(routes.serviceMode);
  }, [trackEvent, navigate, noStoreSelected, openConfirmationModal, serviceMode]);
  const handleNavigateToOrderConfirmation = useCallback(
    (rbiOrderId: string) => () => {
      trackEvent({
        name: CustomEventNames.BOTTOM_SERVICE_MODE,
        type: EventTypes.Other,
        attributes: {
          'Service Mode': serviceMode,
          'Order Id': rbiOrderId,
        },
      });
      updateUserAttributes({ 'Service Mode': serviceMode }, {}, sendUpdateUserAttributesEvent);
      navigate(`${routes.orderConfirmation}/${rbiOrderId}`, {
        state: { showToast: true },
      });
    },
    [trackEvent, navigate, serviceMode]
  );
  // We don't want to block user from ordering when there is existing
  // order in progress. We will always shows default bottom service mode
  // on menu page
  const recentOrder = orders[0];

  // This will change the display of BSM after the order has been placed
  // and delivery is being tracked, a waiting that pickup from curbside.
  // Any refunds will not block users from making a new order
  if (!isOnMenuPage && isAuth && recentOrder?.status !== RbiOrderStatus.REFUND_SUCCESSFUL) {
    // Completed delivery order is when status is completed and
    // exceeded 10 minutes after order is dropped off (in order for user to view the order)
    const isDeliveryOrderCompleted =
      !!recentOrder?.delivery &&
      DELIVERY_COMPLETED.includes(recentOrder.delivery.status) &&
      (hasSeenCancelModal ||
        deliveryCompleteTimeout(recentOrder?.updatedAt, DELIVERY_COMPLETED_TIMEOUT_IN_MINUTES));

    if (isPollingForDelivery(recentOrder, isDeliveryOrderCompleted)) {
      if (hasSeenCancelModal) {
        setHasSeenCancelModal(false);
      }
      startPolling(ORDER_PROGRESS_POLLING_DURATION_IN_MS);
    }

    const inProgressResult = orderInProgress({
      recentOrder,
      fireOrderAhead,
      curbsidePickupOrderId,
      curbsidePickupOrderTimePlaced,
      isDeliveryOrderCompleted,
    });
    if (inProgressResult) {
      const handleNavArg: string = curbsidePickupOrderId || recentOrder?.rbiOrderId;
      const onButtonClick =
        recentOrder?.delivery?.status === DeliveryStatus.ORDER_CANCELLED
          ? openCancellationModal
          : handleNavigateToOrderConfirmation(handleNavArg);

      return {
        ...inProgressResult,
        heading: formatMessage({ id: inProgressResult.headingId }),
        details: formatMessage({ id: inProgressResult.detailsId }),
        buttonContentText: formatMessage({ id: inProgressResult.buttonContentTextId }),
        onButtonClick,
        onBottomServiceClick: onButtonClick,
        CancellationModal,
        ConfirmationModal,
        refetchUserOrder: refetch,
      };
    }
  }

  const resultBase: IBottomServiceModeDetail = {
    heading: formatMessage({ id: 'forItemAvailability' }),
    details: formatMessage({ id: 'chooseALocation' }),
    icon: (
      <Icon
        icon="location"
        color={theme.iconContainer.color}
        width="24px"
        height="24px"
        aria-hidden
      />
    ),
    buttonContentText: cartButtonText,
    buttonContentTextLabel: `${cartButtonText} ${formatMessage({ id: 'cartTotal' })}`,
    buttonContentIcon: <Icon icon="cart" color={theme.iconContainer.color} aria-hidden />,
    buttonBadgeContentText: !inRestaurantRedemptionCart?.length ? rewardQuantity : '',
    onButtonClick: handleCartClick,
    onBottomServiceClick: handleServiceModeChange,
    buttonDisabled: isCartEmpty,
    variant: BottomServiceVariants.DEFAULT,
    CancellationModal,
    ConfirmationModal,
    refetchUserOrder: refetch,
  };

  if (called) {
    stopPolling();
  }

  if (store._id) {
    const address = store.customerFacingAddress?.locale || store.physicalAddress?.address1;
    const isMobileOrderingEnabled = store.hasMobileOrdering && enableOrdering;

    if (!isMobileOrderingEnabled && address) {
      return {
        ...resultBase,
        heading: formatMessage({ id: 'yourSelectedStore' }),
        details: address,
        icon: (
          <Icon
            icon="restaurant"
            color={theme.iconContainer.color}
            width="24px"
            height="24px"
            aria-hidden
          />
        ),
        buttonContentText: '',
        buttonContentIcon: null,
      };
    }
    switch (serviceMode) {
      case ServiceMode.DELIVERY:
        if (deliveryAddress) {
          return {
            ...resultBase,
            heading: formatMessage({ id: 'delivery' }),
            details: deliveryAddress.addressLine1,
            icon: (
              <Icon
                icon={theme.delivery.icon}
                color={theme.iconContainer.color}
                width={theme.delivery.width}
                height={theme.delivery.height}
                aria-hidden
              />
            ),
          };
        }
        break;
      case ServiceMode.CATERING_DELIVERY:
        if (deliveryAddress) {
          return {
            ...resultBase,
            heading: formatMessage({ id: 'cateringDashDelivery' }),
            details: deliveryAddress.addressLine1,
            icon: (
              <Icon
                icon={theme.catering.delivery.icon}
                color={theme.iconContainer.color}
                width={theme.catering.delivery.width}
                height={theme.catering.delivery.height}
                aria-hidden
              />
            ),
          };
        }
        break;
      case ServiceMode.CATERING_PICKUP:
        if (address) {
          return {
            ...resultBase,
            heading: formatMessage({ id: 'cateringDashPickUp' }),
            details: address,
            icon: (
              <Icon
                icon={theme.catering.pickup.icon}
                color={theme.iconContainer.color}
                width={theme.catering.pickup.width}
                height={theme.catering.pickup.height}
                aria-hidden
              />
            ),
          };
        }
        break;
      case ServiceMode.CURBSIDE:
        if (address) {
          return {
            ...resultBase,
            heading: formatMessage({ id: 'curbside' }),
            details: address,
            icon: (
              <Icon
                icon={theme.pickup.icon}
                color={theme.iconContainer.color}
                width={theme.catering.pickup.width}
                height={theme.catering.pickup.height}
                aria-hidden
              />
            ),
          };
        }
        break;
      case ServiceMode.TAKEOUT:
      case ServiceMode.TABLE_SERVICE:
      case ServiceMode.DRIVE_THRU:
      case ServiceMode.EAT_IN:
        if (address) {
          let result = {
            ...resultBase,
            heading: formatMessage({
              id: serviceMode === ServiceMode.TABLE_SERVICE ? 'tableService' : 'pickUp',
            }),
            details: address,
            icon: (
              <Icon
                icon={theme.pickup.icon}
                color={theme.iconContainer.color}
                width={theme.pickup.width}
                height={theme.pickup.height}
                aria-hidden
              />
            ),
          };

          if (inRestaurantRedemptionEnabled && inRestaurantRedemptionCart?.length) {
            // Badge should display quantity of items added to inRestaurantCart
            const badgeContent = inRestaurantRedemptionCart.reduce(
              (acc, entry) => (acc += entry.quantity),
              0
            );

            result = {
              ...result,
              heading: formatMessage({ id: LOYALTY_REDEEM_TEXT_KEY }),
              details: address,
              icon: <LoyaltyPointsIcon />,
              buttonBadgeContentText: `${badgeContent}`,
              buttonContentText: formatMessage({ id: 'redeem' }),
              buttonContentIcon: undefined,
              buttonDisabled: false,
              onButtonClick: () => navigate(formatMessage({ id: 'routes.redemptionInRestaurant' })),
              variant: BottomServiceVariants.IN_RESTAURANT_LOYALTY,
            };
          }

          return result;
        }
        break;
      default:
    }
  }
  return {
    ...resultBase,
    buttonContentText: '0',
    buttonContentTextLabel: `0 ${formatMessage({ id: 'cartTotal' })}`,
    buttonContentIcon: <Icon icon="cart" color="black" aria-hidden />,
    buttonDisabled: undefined,
    variant: BottomServiceVariants.NO_STORE,
  };
};
